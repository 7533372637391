import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Modal } from "components/ui";
import { Select } from "components/forms/Select";

function TrainerModalActivity({ data, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [activitiesOptions, setActivitiesOptions] = useState([]);
    const [trainer, setTrainer] = useState(null);
    const [trainerActivities, setTrainerActivities] = useState([]);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        setLoading(true);

        Promise.all([
            AxiosInstance({
                url: "brands/" + data.brandId + "/trainers/" + data.trainerId,
                method: "GET",
            }).then((response) => {
                setTrainer(response.data);
                setTrainerActivities(response.data.activities);
            }),

            AxiosInstance({
                url: "brands/" + data.brandId + "/activities",
                method: "GET",
            }).then((response) => {
                setActivitiesOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    };

    const handleSubmit = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "brands/" + data.brandId + "/trainers/" + data.trainerId + "/activities",
            method: "PUT",
            data: trainerActivities,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.status === 201) {
                    handleLoadData();
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Activities conducted by a trainer", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : trainer ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <strong>{t("Trainer", { ns: "common" })}:</strong>
                            {trainer.fullName}
                        </div>

                        <div className="flex-auto w-full">
                            <Select
                                isMulti={true}
                                options={activitiesOptions}
                                name={"trainerActivities"}
                                value={trainerActivities}
                                getOptionLabel={(option) => option.name + " - " + option.city.name}
                                onChange={(e, activity) => setTrainerActivities(activity)}
                            />
                        </div>

                        <div className="border-t"></div>

                        <div className="flex justify-center gap-2">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                                onClick={() => handleSubmit()}
                            >
                                {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                            </button>
                            <button type="button" className="btn btn-gray" disabled={isSubmitting} onClick={closeModal}>
                                {t("Cancel", { ns: "button" })}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                t("No trainer found", { ns: "common" })
            )}
        </Modal>
    );
}

export default TrainerModalActivity;
