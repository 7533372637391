import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatTime } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Modal } from "components/ui";
import { getInitialValues } from "components/forms/helpers";
import { DatePicker, SelectField, TimePicker } from "components/forms";
import { DisplayDayTime } from "utils/DisplayDate";

function ModalEnrollmentChangeDate({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [enrollment, setEnrollment] = useState(null);
    const [activitySchedulesOptions, setActivitySchedulesOptions] = useState([]);

    const defaultValues = {
        date: [
            {
                id: null,
                day: null,
                time: null,
            },
        ],
        moveEventsFrom: null,
        moveEventsType: null,
    };

    const validationSchema = Yup.object().shape({
        date: Yup.object().when("activity.period", {
            is: (period) => period && period.value === "SINGLE",
            then: () =>
                Yup.array(
                    Yup.object().shape({
                        day: Yup.date().required(
                            t("The {{label}} field is required.", {
                                ns: "validation",
                                label: t("Date of activity", { ns: "common" }),
                            })
                        ),
                        time: Yup.date().required(
                            t("The {{label}} field is required.", {
                                ns: "validation",
                                label: t("Hour", { ns: "common" }),
                            })
                        ),
                    })
                ),
            otherwise: () =>
                Yup.array().of(
                    Yup.object().shape({
                        id: Yup.number().required(
                            t("The {{label}} field is required.", {
                                ns: "validation",
                                label: t("Date of activity", { ns: "common" }),
                            })
                        ),
                    })
                ),
        }),
        moveEventsFrom: Yup.object().when("activity.period", {
            is: (period) => period && period.value === "CYCLICAL",
            then: () =>
                Yup.date().required(
                    t("The {{label}} field is required.", {
                        ns: "validation",
                        label: t("The change is effective from", { ns: "common" }),
                    })
                ),
            otherwise: () => Yup.string().nullable(),
        }),
        moveEventsType: Yup.object().when("activity.period", {
            is: (period) => period && period.value === "CYCLICAL",
            then: () =>
                Yup.object().required(
                    t("The {{label}} field is required.", {
                        ns: "validation",
                        label: t("Move events in the calendar", { ns: "common" }),
                    })
                ),
            otherwise: () => Yup.string().nullable(),
        }),
    });

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "enrollments/" + data.id,
                method: "GET",
            }).then((response) => {
                setEnrollment(response.data);
            }),

            AxiosInstance({
                url: "brands/" + data.brand.id + "/activities/" + data.activity.id + "/schedules",
                method: "GET",
            }).then((response) => {
                setActivitySchedulesOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [data]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/" + data.id + "/change-date",
            method: "POST",
            data: values,
        })
            .then((response) => {
                handleLoadData();

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Change the date of activity", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Formik
                        initialValues={getInitialValues(defaultValues, enrollment)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleSubmit(values, setSubmitting, resetForm);
                        }}
                        validateOnMount
                        enableReinitialize
                    >
                        {({
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            setFieldValue,
                            setFieldTouched,
                            values,
                            validateForm,
                        }) => {
                            return (
                                <Form>
                                    <div className="flex flex-col gap-4 w-full">
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col gap-2">
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Activity", { ns: "common" })}:</strong>
                                                    {enrollment.activity.name}
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Institution", { ns: "common" })}:</strong>
                                                    {enrollment.institution && enrollment.institution.name}
                                                </div>
                                                {enrollment.activity.period.value === "SINGLE" ? (
                                                    <>
                                                        {enrollment.date.map((d) => {
                                                            return (
                                                                <>
                                                                    <div className="flex items-center gap-2">
                                                                        <strong>{t("Date", { ns: "common" })}:</strong>
                                                                        <FormatDate date={d.day} />
                                                                    </div>
                                                                    <div className="flex items-center gap-2">
                                                                        <strong>{t("Hour", { ns: "common" })}:</strong>
                                                                        <FormatTime
                                                                            date={d.time}
                                                                            formatting={{
                                                                                hour: "numeric",
                                                                                minute: "numeric",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <div className="flex items-center gap-2">
                                                        <strong>{t("Date of activity", { ns: "common" })}:</strong>
                                                        {enrollment.date
                                                            .map((d) => {
                                                                return (
                                                                    <DisplayDayTime day={d.day.name} time={d.time} />
                                                                );
                                                            })
                                                            .reduce((prev, curr) => [prev, ", ", curr])}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="border-t"></div>

                                            {values.activity.period && values.activity.period.value === "SINGLE" ? (
                                                values.date.map((d, i) => (
                                                    <Fragment key={i}>
                                                        <Field
                                                            component={DatePicker}
                                                            key={"date.day." + i}
                                                            name={"date[" + i + "][day]"}
                                                            minDate={dayjs()}
                                                            label={t("Date of activity", { ns: "common" })}
                                                        />
                                                        <Field
                                                            component={TimePicker}
                                                            key={"date.time." + i}
                                                            name={"date[" + i + "][time]"}
                                                            label={t("Hour", { ns: "common" })}
                                                            required={true}
                                                        />
                                                    </Fragment>
                                                ))
                                            ) : (
                                                <>
                                                    {values.date.map((d, index) => (
                                                        <Field
                                                            component={SelectField}
                                                            key={index}
                                                            name={`date.${index}`}
                                                            label={t("Date", { ns: "common" })}
                                                            required={true}
                                                            options={activitySchedulesOptions}
                                                            getOptionDisabled={(option) => !option.active}
                                                            getOptionLabel={(option) => {
                                                                return (
                                                                    t(option.day.name, { ns: "lexicons" }) +
                                                                    " - " +
                                                                    dayjs(option.time).format("HH:mm")
                                                                );
                                                            }}
                                                        />
                                                    ))}

                                                    <Field
                                                        component={DatePicker}
                                                        key={"moveEventsFrom"}
                                                        name={"moveEventsFrom"}
                                                        label={t("The change is effective from", { ns: "common" })}
                                                        required={true}
                                                        minDate={dayjs()}
                                                    />

                                                    <Field
                                                        component={SelectField}
                                                        key={"moveEventsType"}
                                                        name={"moveEventsType"}
                                                        label={t("Move events in the calendar", { ns: "common" })}
                                                        required={true}
                                                        options={[
                                                            {
                                                                value: "forward",
                                                                name: "forward",
                                                            },
                                                            {
                                                                value: "back",
                                                                name: "back",
                                                            },
                                                        ]}
                                                        getOptionLabel={(option) => {
                                                            return t(option.name, { ns: "common" });
                                                        }}
                                                    />
                                                </>
                                            )}

                                            <div className="flex justify-center gap-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={!isValid || isSubmitting}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isSubmitting
                                                        ? t("Saving", { ns: "button" })
                                                        : t("Save", { ns: "button" })}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-gray"
                                                    disabled={isSubmitting}
                                                    onClick={closeModal}
                                                >
                                                    {t("Cancel", { ns: "button" })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Modal>
    );
}

export default ModalEnrollmentChangeDate;
