"use client";

// React Imports
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

// MUI Imports
import Tooltip from "@mui/material/Tooltip";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

import { IoEllipsisVerticalOutline } from "react-icons/io5";
import { DownloadPdf } from "utils/DownloadPdf";

const IconButtonWrapper = (props) => {
    // Props
    const { tooltipProps, children } = props;

    return tooltipProps?.title ? (
        <Tooltip placement="top" {...tooltipProps}>
            {children}
        </Tooltip>
    ) : (
        children
    );
};

const MenuItemWrapper = ({ children, option }) => {
    if (option.href) {
        return (
            <Link
                to={option.href}
                className={"flex gap-2 px-4 w-full items-center"}
                style={{ paddingTop: "6px", paddingBottom: "6px" }}
                {...option.linkProps}
            >
                {children}
            </Link>
        );
    } else {
        return <>{children}</>;
    }
};

export const OptionMenu = (props) => {
    // Props
    const { tooltipProps, icon, iconButtons, options, leftAlignMenu, iconButtonProps } = props;

    // States
    const [open, setOpen] = useState(false);

    // Refs
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div className="flex justify-end items-center h-full">
            {iconButtons &&
                iconButtons.length > 0 &&
                iconButtons.map((option, index) => (
                    <IconButtonWrapper key={index} tooltipProps={option.tooltipProps}>
                        <IconButton
                            ref={anchorRef}
                            size="medium"
                            onClick={(e) => {
                                handleClose(e);
                                option.menuItemProps && option.menuItemProps.onClick && option.menuItemProps.onClick(e);
                            }}
                            sx={{
                                color: "#000",
                            }}
                            {...iconButtonProps}
                        >
                            {option.icon}
                        </IconButton>
                    </IconButtonWrapper>
                ))}

            {options && options.length > 0 && (
                <>
                    <IconButtonWrapper tooltipProps={tooltipProps}>
                        <IconButton
                            ref={anchorRef}
                            size="medium"
                            onClick={handleToggle}
                            sx={{
                                color: "#000",
                            }}
                            {...iconButtonProps}
                        >
                            {icon ? icon : <IoEllipsisVerticalOutline size={".875em"} />}
                        </IconButton>
                    </IconButtonWrapper>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        placement={leftAlignMenu ? "bottom-start" : "bottom-end"}
                        transition
                        // disablePortal
                        sx={{
                            zIndex: 1400,
                        }}
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps}>
                                <Paper
                                    className={"shadow-lg ring-1 ring-black ring-opacity-5"}
                                    sx={{
                                        backgroundColor: "#e5e7eb",
                                        borderRadius: "0.375rem",
                                        minWidth: "10rem",
                                    }}
                                >
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open}>
                                            {options.map((option, index) => {
                                                if (typeof option === "string") {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            onClick={handleClose}
                                                            className="!text-sm"
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    );
                                                } else if ("divider" in option) {
                                                    return (
                                                        option.divider && (
                                                            <Divider key={index} {...option.dividerProps} />
                                                        )
                                                    );
                                                } else if ("pdf" in option) {
                                                    return (
                                                        <DownloadPdf
                                                            key={index}
                                                            invoiceFileName={option.props.invoiceFileName}
                                                            invoiceSlug={option.props.invoiceSlug}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            {...option.menuItemProps}
                                                            {...(option.href && { className: "!p-0" })}
                                                            onClick={(e) => {
                                                                handleClose(e);
                                                                option.menuItemProps &&
                                                                    option.menuItemProps.onClick &&
                                                                    option.menuItemProps.onClick(e);
                                                            }}
                                                            sx={{
                                                                gap: "0.5rem",
                                                                fontSize: "0.875rem",
                                                                lineHeight: "1.25rem",
                                                            }}
                                                        >
                                                            <MenuItemWrapper option={option}>
                                                                {option.icon || null}
                                                                {option.text}
                                                                {option.component}
                                                            </MenuItemWrapper>
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </>
            )}
        </div>
    );
};
