import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatTime } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Modal } from "components/ui";
import { SelectField } from "components/forms";
import { DisplayDateTime, DisplayDayTime } from "utils/DisplayDate";
import { createFilterOptions } from "@mui/material";

function ModalEnrollmentTrainer({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [enrollment, setEnrollment] = useState(null);
    const [trainersOptions, setTrainersOptions] = useState([]);
    const [trainers, setTrainers] = useState(null);

    const validationSchema = Yup.object().shape({});

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "enrollments/" + data.id,
                method: "GET",
            }).then((response) => {
                setEnrollment(response.data);
            }),

            AxiosInstance({
                url: "brands/" + data.brand.id + "/trainers",
                method: "GET",
            }).then((response) => {
                setTrainersOptions(response.data);
            }),

            AxiosInstance({
                url: "enrollments/" + data.id + "/trainers",
                method: "GET",
            }).then((response) => {
                setTrainers(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [data]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/" + data.id + "/trainers",
            method: "PUT",
            data: values,
        })
            .then((response) => {
                handleLoadData();

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Trainers conducting activities", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : trainers ? (
                <Formik
                    initialValues={trainers}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                        return (
                            <Form>
                                <div className="flex flex-col gap-4 w-full">
                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col gap-2">
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Activity", { ns: "common" })}:</strong>
                                                {enrollment.activity.name}
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Institution", { ns: "common" })}:</strong>
                                                {enrollment.institution !== null ?? enrollment.institution.name}
                                            </div>
                                            {enrollment.activity.period.value === "SINGLE" ? (
                                                <>
                                                    {enrollment.date.map((d) => {
                                                        return (
                                                            <>
                                                                <div className="flex items-center gap-2">
                                                                    <strong>{t("Date", { ns: "common" })}:</strong>
                                                                    <FormatDate date={d.day} />
                                                                </div>
                                                                <div className="flex items-center gap-2">
                                                                    <strong>{t("Hour", { ns: "common" })}:</strong>
                                                                    <FormatTime
                                                                        date={d.time}
                                                                        formatting={{
                                                                            hour: "numeric",
                                                                            minute: "numeric",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Date of activity", { ns: "common" })}:</strong>
                                                    {enrollment.date
                                                        .map((d) => {
                                                            return <DisplayDayTime day={d.day.name} time={d.time} />;
                                                        })
                                                        .reduce((prev, curr) => [prev, ", ", curr])}
                                                </div>
                                            )}
                                        </div>

                                        <div className="border-t"></div>

                                        <FieldArray
                                            name="test"
                                            render={(arrayHelpers) => (
                                                <>
                                                    {enrollment.date.map((d, index) => (
                                                        <Field
                                                            key={index}
                                                            component={SelectField}
                                                            isMulti={true}
                                                            name={`${index}.trainers`}
                                                            label={
                                                                enrollment.activity.period.value === "SINGLE" ? (
                                                                    <DisplayDateTime date={d.day} time={d.time} />
                                                                ) : (
                                                                    <DisplayDayTime day={d.day.name} time={d.time} />
                                                                )
                                                            }
                                                            required={true}
                                                            fieldClassName={"col-span-2"}
                                                            options={trainersOptions}
                                                            getOptionLabel={(option) => option.fullName}
                                                            filterOptions={createFilterOptions({
                                                                matchFrom: "any",
                                                                stringify: (option) => option.fullName,
                                                            })}
                                                        />
                                                    ))}
                                                </>
                                            )}
                                        />

                                        <div className="flex justify-center gap-2">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={isSubmitting}
                                                onClick={() => handleSubmit()}
                                            >
                                                {isSubmitting
                                                    ? t("Saving", { ns: "button" })
                                                    : t("Save", { ns: "button" })}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-gray"
                                                disabled={isSubmitting}
                                                onClick={closeModal}
                                            >
                                                {t("Cancel", { ns: "button" })}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                t("No trainer found", { ns: "common" })
            )}
        </Modal>
    );
}

export default ModalEnrollmentTrainer;
