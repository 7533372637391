import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatPrice } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Badge, Modal, OptionMenu } from "components/ui";
import { DataGrid } from "components/grid";
import { IoCardOutline } from "react-icons/io5";

function ModalActivityPayments({ billing, closeModal }) {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [payments, setPayments] = useState([]);

    const columns = [
        {
            field: "paymentId",
            headerName: t("Payment ID", { ns: "common" }),
            width: 120,
            valueGetter: (value, row) => `${value || "---"}`,
        },
        {
            field: "dueDate",
            headerName: t("Due date", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                return <FormatDate date={params.row.dueDate} />;
            },
        },
        {
            field: "paymentDate",
            headerName: t("Payment date", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                return <FormatDate date={params.row.paymentDate} />;
            },
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                let color = "";
                switch (params.row.status.value) {
                    case "NEW":
                        color = "#0284c7";
                        break;
                    case "PENDING":
                        color = "#ca8a04";
                        break;
                    case "COMPLETED":
                        color = "#166534";
                        break;
                    case "CANCELLED":
                    case "REJECTED":
                        color = "#313333";
                        break;
                    case "FAILED":
                        color = "#dc2626";
                        break;
                    default:
                        color = "#313333";
                        break;
                }

                return (
                    <div className="flex items-center h-full">
                        <Badge color={color}>{t(params.row.status.name, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "period",
            headerName: t("Period", { ns: "common" }),
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <FormatDate date={params.row.period.start} />
                        <span> - </span>
                        <FormatDate date={params.row.period.end} />
                    </>
                );
            },
        },
        {
            field: "amount",
            headerName: t("Amount", { ns: "common" }),
            width: 100,
            renderCell: (params) => {
                return <FormatPrice price={params.row.amount} />;
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 80,
            renderCell: (params) => {
                let options = [];

                (params.row.status.value === "NEW" ||
                    params.row.status.value === "PENDING" ||
                    params.row.status.value === "FAILED" ||
                    params.row.status.value === "CANCELLED" ||
                    params.row.status.value === "REJECTED") &&
                    options.push({
                        text: t("Pay", { ns: "button" }),
                        icon: <IoCardOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => handleSubmit(params.row.orderId),
                        },
                    });

                params.row.invoices.length > 0 &&
                    options.push({
                        pdf: 1,
                        props: {
                            invoiceSlug: params.row.invoices[0].slug,
                            invoiceFileName: params.row.invoices[0].fileName,
                        },
                    });

                return <OptionMenu options={options} />;
            },
        },
    ];

    useEffect(() => {
        AxiosInstance({
            url: "billings/" + billing.id + "/payments",
            method: "GET",
        })
            .then((response) => {
                setPayments(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [billing]);

    const handleSubmit = async (orderId) => {
        setSubmitting(true);

        navigate("/payment/" + orderId);
    };

    return (
        <Modal handleClose={closeModal} title={t("Information about payments", { ns: "common" })} big={true}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <DataGrid rows={payments} columns={columns} staticHeight={true} disableColumnSorting />
            )}
        </Modal>
    );
}

export default ModalActivityPayments;
