import { useEffect } from "react";

import AxiosInstance from "utils/AxiosInstance";
import AuthService from "../services/AuthService";
import { useSessionStorage } from "./useSessionStorage";

export function useLexicons(name) {
    const [lexicons, setLexicons] = useSessionStorage("lexicons", null);

    useEffect(() => {
        const getData = async () => {
            await AxiosInstance({
                url: "lexicons",
                method: "GET",
            }).then((response) => {
                setLexicons(response.data);
            });
        };

        if (AuthService.isToken() && (!lexicons || lexicons === null)) {
            getData();
        }
    }, []);

    return lexicons !== null ? (name ? lexicons[name] : lexicons) : null;
}
