import React, { Profiler, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useLocalStorage } from "hooks/useLocalStorage";
import { useSessionStorage } from "hooks/useSessionStorage";
import AuthService from "services/AuthService";

import { ChooseAccountType } from "./ChooseAccountType";
import { ParentStep1, ParentStep2, ParentStep3, ParentStep4, ParentStep5 } from "./ParentSteps/components";
import { BrandStep1, BrandStep2, BrandStep3, BrandStep4, BrandStep5 } from "./BrandSteps/components";

export const SignUp = ({ type }) => {
    const { t } = useTranslation();

    const feedback = {
        0: t("too short", { ns: "common" }),
        1: t("weak", { ns: "common" }),
        2: t("okay", { ns: "common" }),
        3: t("good", { ns: "common" }),
        4: t("strong", { ns: "common" }),
    };

    const [loginBy, setLoginBy] = useLocalStorage("loginBy", "phone", true);
    const [signUpData, setSignUpData] = useSessionStorage("signUpData", null);

    const [validate, setValidate] = useState({
        hasLow: false,
        hasCap: false,
        hasNumber: false,
        hasSpecial: false,
        has8digit: false,
    });

    const [passwordValidate, setPasswordValidate] = useState(null);

    const [authenticated, setAuthenticated] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (AuthService.isToken()) {
            setAuthenticated(true);
        }

        if (type === null) {
            setSignUpData(null);
        } else if (signUpData === null) {
            setSignUpData({
                type: type,
                step: 1,
                loginBy: loginBy,
                data: {
                    type: type,
                    code: "",
                    confirmPassword: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                    phone: "",
                    marketingAgreement: false,
                    privacyPolicy: false,
                    termsConditions: false,
                },
            });
        } else if (signUpData.data.password && signUpData.data.password.length > 0) {
            // setSignUpData({ ...signUpData, data: { ...signUpData.data, password: "" } });
        }

        if (passwordValidate === null && signUpData != null && signUpData.data.password) {
            validatePassword(signUpData.data.password);
        }

        const strength = Object.values(validate).reduce((a, item) => a + item, 0);
        setPasswordValidate({ strength: strength, feedback: feedback[strength] });
    }, [signUpData, setSignUpData, type, loginBy, validate]);

    // Handle fields change
    const handleChange = (input) => (e) => {
        let value = "";

        if ("code" === input) {
            value = e;
        } else if ("checkbox" === e.target.type) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        setSignUpData((signUpData) => {
            return {
                ...signUpData,
                data: {
                    ...signUpData.data,
                    [input]: value,
                },
            };
        });

        if ("password" === input) {
            validatePassword(e.target.value);
        }
    };

    const handleSignUpBy = (by) => {
        setSignUpData(null);
        setLoginBy(by);
    };

    const validatePassword = (password) => {
        if (password.length > 7) {
            // setValidate((o) => ({ ...o, has8digit: true }));

            if (password.match(/\d+/g)) {
                setValidate((o) => ({ ...o, hasNumber: true }));
            } else {
                setValidate((o) => ({ ...o, hasNumber: false }));
            }

            if (password.match(/[A-Z]+/g)) {
                setValidate((o) => ({ ...o, hasCap: true }));
            } else {
                setValidate((o) => ({ ...o, hasCap: false }));
            }

            if (password.match(/[a-z]+/g)) {
                setValidate((o) => ({ ...o, hasLow: true }));
            } else {
                setValidate((o) => ({ ...o, hasLow: false }));
            }

            if (password.match(/[^A-Za-z0-9]+/g)) {
                setValidate((o) => ({ ...o, hasSpecial: true }));
            } else {
                setValidate((o) => ({ ...o, hasSpecial: false }));
            }
        } else {
            setValidate({
                hasLow: false,
                hasCap: false,
                hasNumber: false,
                hasSpecial: false,
                has8digit: false,
            });
        }
    };

    const nextStep = async () => {
        setSubmitting(true);

        AuthService.signup(signUpData.step, signUpData.data, signUpData.loginBy).then(
            (response) => {
                setSignUpData({ ...signUpData, error: null });

                if (response.status && response.status === true) {
                    if (5 === signUpData.step && response.token) {
                        sessionStorage.setItem("uid", response.token);
                        sessionStorage.removeItem("signUpData");
                        window.location.reload();
                        return;
                    }

                    setSignUpData({ ...signUpData, step: signUpData.step + 1 });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            },
            (error) => {
                setSignUpData({ ...signUpData, error: true });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            }
        );
    };

    const resetSignUp = () => {
        setSignUpData({ type: type, step: 1, data: {} });
    };

    const reSendCodeOTP = () => {
        setSubmitting(true);

        AuthService.signup(1, signUpData.data, signUpData.loginBy).then(
            (response) => {
                if (response.status && response.status === true) {
                    setSignUpData({ ...signUpData, error: null });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            },
            (error) => {
                setSignUpData({ ...signUpData, error: true });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            }
        );
    };

    const getSectionComponent = () => {
        if (signUpData === null) {
            return <ChooseAccountType />;
        } else if (signUpData.type === 1 || signUpData.type === 2) {
            switch (signUpData.step) {
                case 1:
                default:
                    return (
                        <ParentStep1
                            handleChange={handleChange}
                            handleSignUpBy={handleSignUpBy}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 2:
                    return (
                        <ParentStep2
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            reSendCodeOTP={reSendCodeOTP}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 3:
                    return (
                        <ParentStep3
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            passwordValidate={passwordValidate}
                            error={signUpData.error}
                        />
                    );
                case 4:
                    return (
                        <ParentStep4
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            passwordValidate={passwordValidate}
                            error={signUpData.error}
                        />
                    );
                case 5:
                    return (
                        <ParentStep5
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
            }
        } else if (signUpData.type === 3) {
            switch (signUpData.step) {
                case 1:
                default:
                    return (
                        <BrandStep1
                            handleChange={handleChange}
                            handleSignUpBy={handleSignUpBy}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 2:
                    return (
                        <BrandStep2
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            reSendCodeOTP={reSendCodeOTP}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 3:
                    return (
                        <BrandStep3
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            passwordValidate={passwordValidate}
                            error={signUpData.error}
                        />
                    );
                case 4:
                    return (
                        <BrandStep4
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
                case 5:
                    return (
                        <BrandStep5
                            handleChange={handleChange}
                            nextStep={nextStep}
                            resetSignUp={resetSignUp}
                            loginBy={loginBy}
                            isSubmitting={isSubmitting}
                            data={signUpData.data}
                            error={signUpData.error}
                        />
                    );
            }
        }
    };

    if (authenticated) {
        return <Navigate to="/" replace={true} />;
    } else {
        return <Profiler id="SignUp">{getSectionComponent()}</Profiler>;
    }
};
