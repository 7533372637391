import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { Box, ContentHeading } from "components/ui";
import { FormSchema } from "../formSchema";

function BrandForm({ brand }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formSchema = FormSchema();

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "brands";
        let method = "POST";

        if (brand) {
            url = "brands/" + brand.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!brand && response.status === 201) {
                    resetForm({ values: "" });
                    navigate("/brand/brands");
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">
                {brand ? t("Edit offer", { ns: "common" }) : t("Add offer", { ns: "common" })}
            </ContentHeading>

            <Box>
                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    initialValues={brand}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonLink={"/brand/brands"}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            </Box>
        </>
    );
}

export default BrandForm;
