import AxiosInstance from "utils/AxiosInstance";

class AuthService {
    signup(step, data, by = "phone") {
        return AxiosInstance({
            url: "signup/by-" + by + "/step-" + step,
            method: "POST",
            data: data,
        }).then((response) => {
            return response.data;
        });
    }

    auth(data, by = "phone") {
        return AxiosInstance({
            url: "auth/by-" + by,
            method: "POST",
            data: data,
        }).then((response) => {
            return response.data;
        });
    }

    passwordReset(data) {
        return AxiosInstance({
            url: "password/reset",
            method: "POST",
            data: data,
        }).then((response) => {
            return response.data;
        });
    }

    login(data, by = "phone") {
        return AxiosInstance({
            url: "signin/by-" + by,
            method: "POST",
            data: data,
        }).then((response) => {
            if (response.data.token) {
                sessionStorage.setItem("uid", response.data.token);
            }

            return response.data;
        });
    }

    logout() {
        sessionStorage.clear();
    }

    isToken() {
        if (sessionStorage.getItem("uid")) return true;

        return false;
    }
}

export default new AuthService();
