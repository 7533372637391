import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { Modal } from "components/ui";
import GroupForm from "./GroupForm";

function GroupAddModal({ closeModal, handleLoadGroups }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/groups",
            method: "POST",
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (response.status === 201) {
                    handleLoadGroups();
                    resetForm({ values: "" });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Add group", { ns: "common" })}>
            <GroupForm handleSubmit={handleSubmit} />
        </Modal>
    );
}

export default GroupAddModal;
