import React from "react";
import { useTranslation } from "react-i18next";

import { DisplayDayTime } from "utils/DisplayDate";
import { FormatDate, FormatMinutes, FormatPrice, FormatTime } from "utils/Format";
import { Button, Modal } from "components/ui";
import { LowestPrice } from "utils/LowestPrice";

export const ModalView = ({ data, openModal, closeModal }) => {
    const { t } = useTranslation();

    return (
        <Modal handleClose={closeModal} title={t("Information about activity", { ns: "common" })}>
            {data.activity ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity", { ns: "common" })}:</strong>
                            {data.activity.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Description", { ns: "common" })}:</strong>
                            {data.activity.description}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Brand", { ns: "common" })}:</strong>
                            {data.activity.brand.name}
                        </div>
                        {data.activity.period.value === "SINGLE" ? (
                            <>
                                <div className="flex items-center gap-2">
                                    <strong>{t("Date", { ns: "common" })}:</strong>
                                    <FormatDate date={data.activity.date[0].day} />
                                </div>
                                <div className="flex items-center gap-2">
                                    <strong>{t("Hour", { ns: "common" })}:</strong>
                                    <FormatTime
                                        date={data.activity.date[0].time}
                                        formatting={{ hour: "numeric", minute: "numeric" }}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="flex items-center gap-2">
                                <strong>{t("Available dates", { ns: "common" })}:</strong>
                                <div className="flex flex-col h-24 w-full overflow-y-auto ring-1 ring-slate-200 max-w-sm rounded-sm">
                                    {data.activity.date.map((d, index) => (
                                        <div className="flex py-1 px-3 bg-slate-50" key={index}>
                                            <DisplayDayTime day={d.day.name} time={d.time} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="flex items-center gap-2">
                            <strong>{t("Type of activity", { ns: "common" })}:</strong>
                            {t(data.activity.period.name, { ns: "lexicons" })}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity length", { ns: "common" })}:</strong>
                            <FormatMinutes minutes={data.activity.activityLength} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Price from", { ns: "common" })}:</strong>
                            {data.activity.isFree ? (
                                t("Free", { ns: "common" })
                            ) : (
                                <FormatPrice price={LowestPrice(data.activity.billingMethods)} />
                            )}
                        </div>
                    </div>

                    <div className="border-t"></div>

                    <div className="flex flex-row justify-center gap-2">
                        <Button className="btn btn-primary" onClick={() => openModal(data.activity, "enroll")}>
                            {t("Enroll in an activity", { ns: "button" })}
                        </Button>
                    </div>
                </div>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
};
