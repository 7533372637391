import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { Button, Modal } from "components/ui";

function ModalFreePayment({ orderId, closeModal, reloadData }) {
    const { t } = useTranslation();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleConfirm = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "payments/free-and-generate-new",
            method: "POST",
            data: {
                orderId: orderId,
            },
        })
            .then((response) => {
                setTimeout(() => {
                    setSubmitting(false);
                    closeModal();
                    reloadData();
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Set a free period", { ns: "common" })}>
            <div className="flex flex-col gap-4 w-full">
                <div className="text-center">
                    {t(
                        "Are you sure you want to set a free period for this payment and generate a new one for the next billing period?",
                        { ns: "common" }
                    )}
                </div>

                <div className="flex flex-row justify-center gap-2">
                    <Button className={"btn-primary"} onClick={() => handleConfirm()} disabled={isSubmitting}>
                        {t("Yes", { ns: "button" })}
                    </Button>
                    <Button className={"btn-gray"} onClick={() => closeModal()}>
                        {t("No", { ns: "button" })}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalFreePayment;
