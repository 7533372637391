import React from "react";
import { NumericFormat } from "react-number-format";
import { FormField } from "./FormField";

export const NumberField = ({
    id,
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    validation,
    ...props
}) => {
    const handleValueChange = (values) => {
        form.setFieldValue(name, values.value, true);
    };

    return (
        <FormField id={id} label={label} required={required} className={fieldClassName} formProps={form}>
            <NumericFormat
                className={"form-input " + className}
                id={id}
                name={name}
                value={value}
                required={required}
                onValueChange={handleValueChange}
                {...fieldProps}
                {...props}
            />
        </FormField>
    );
};
