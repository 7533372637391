import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { AddressSchema } from "./AddressSchema";

export const BrandTrainerSchema = () => {
    const { t } = useTranslation();

    const [contractTypesOptions, setContractTypesOptions] = useState([]);

    useEffect(() => {
        AxiosInstance({
            url: "contract-types",
            method: "GET",
        }).then((response) => {
            setContractTypesOptions(response.data);
        });
    }, []);

    return [
        {
            name: "firstName",
            label: t("First name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-2",
        },
        {
            name: "lastName",
            label: t("Last name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-2",
        },
        {
            name: "birthday",
            label: t("Birthday", { ns: "common" }),
            componentType: "datePicker",
            required: true,
            fieldClassName: "col-span-full md:col-span-2",
            maxDate: dayjs(),
            openTo: "year",
        },
        {
            name: "email",
            label: t("E-mail", { ns: "common" }),
            componentType: "email",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "phone",
            label: t("Phone", { ns: "common" }),
            componentType: "phone",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "accountNumber",
            label: t("Bank Account No.", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 26,
            validation: {
                regex: {
                    expression: /^[0-9]{26}$/,
                    message: t("The allowed characters are numbers. The required length is 26 numbers.", {
                        ns: "validation",
                    }),
                },
            },
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "contractType",
            label: t("Contract type", { ns: "common" }),
            componentType: "select",
            required: true,
            fieldClassName: "col-span-full md:col-span-3",
            options: contractTypesOptions,
            getOptionLabel: (option) => t(option.name, { ns: "common" }),
        },
        {
            name: "address",
            componentType: "array",
            schema: AddressSchema(),
        },
        {
            name: "description",
            label: t("Description", { ns: "common" }),
            componentType: "textarea",
            required: true,
            fieldClassName: "col-span-full",
        },
        {
            name: "qualifications",
            label: t("Qualifications", { ns: "common" }),
            componentType: "textarea",
            required: true,
            fieldClassName: "col-span-full",
        },
    ];
};
