import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, ImgWithFallback } from "components/ui";
import { Layout } from "./Layout";

import signUpWebP from "../img/signup-step-1.webp";
import signUpPNG from "../img/signup-step-1.png";
import { ButtonSignUpByPhone } from "pages/SignUp/components/ButtonSignUpByPhone";
import { ButtonSigUpByEmail } from "pages/SignUp/components/ButtonSigUpByEmail";

export const Step1 = ({
    handleChange,
    handleSignUpBy,
    nextStep,
    resetSignUp,
    reSendCodeOTP,
    loginBy,
    isSubmitting,
    data,
    error,
}) => {
    const { t } = useTranslation();

    const signUpImg = (
        <ImgWithFallback src={signUpWebP} fallback={signUpPNG} className="flex max-w-xl m-auto" alt="Rejestracja" />
    );

    return (
        <Layout signUpImg={signUpImg}>
            <h3>{t("Welcome to The Kidly!", { ns: "common" })}</h3>
            <p className="mb-0">
                {t("Managing children's services and activities has never been so easy!", { ns: "common" })}
            </p>

            <div className="flex flex-col w-full gap-2">
                <div className="form-field">
                    {loginBy === "email" ? (
                        <>
                            <label htmlFor="email" className="form-label">
                                {t("Email", { ns: "common" })}
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="form-input"
                                maxLength={180}
                                onChange={handleChange("email")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") nextStep();
                                }}
                                value={data.email}
                            />
                        </>
                    ) : (
                        <>
                            <label htmlFor="phone" className="form-label">
                                {t("Phone number", { ns: "common" })}
                            </label>
                            <input
                                type="tel"
                                name="phone"
                                id="phone"
                                className="form-input"
                                pattern="[0-9]{9}"
                                maxLength={9}
                                onChange={handleChange("phone")}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") nextStep();
                                }}
                                value={data.phone}
                            />
                        </>
                    )}
                </div>
            </div>

            <Button className={"btn-primary w-full"} onClick={nextStep} disabled={isSubmitting}>
                {isSubmitting ? t("Sending", { ns: "button" }) : t("Next", { ns: "button" })}
            </Button>

            {loginBy === "email" ? (
                <ButtonSignUpByPhone handleSignUpBy={handleSignUpBy} type={data.type} />
            ) : (
                <ButtonSigUpByEmail handleSignUpBy={handleSignUpBy} type={data.type} />
            )}

            <div className="text-center">
                {t("Do you already have an account?", { ns: "common" })}
                <Link to={"/login"} className="text-primary ml-2">
                    {t("Sign in!", { ns: "common" })}
                </Link>
            </div>
        </Layout>
    );
};
