import React, { useEffect, useState } from "react";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import ActivitiesList from "./components/ActivitiesList";

export const ActivitiesPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [activities, setActivities] = useState(null);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        AxiosInstance({
            url: "enrollments",
            method: "GET",
        })
            .then((response) => {
                setActivities(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <Dashboard contentClass={"activities"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : activities ? (
                <ActivitiesList activities={activities} handleLoadData={handleLoadData} />
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
