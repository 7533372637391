import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { Button, Modal } from "components/ui";
import { enqueueSnackbar } from "notistack";

function ModalBillingDeleteKid({ billing, closeModal }) {
    const { t } = useTranslation();

    const [isSubmitting, setSubmitting] = useState(false);

    const handleConfirm = async (event) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/remove-kid",
            method: "POST",
            data: {
                billing: billing.id,
            },
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                    closeModal();
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Remove kid from activity", { ns: "common" })}>
            <div className="flex flex-col gap-4 w-full">
                <div className="text-center">
                    {t("Are you sure you want to remove the {{kid}} kid from the {{activity}} activity?", {
                        ns: "common",
                        kid: billing.kid.fullName,
                        activity: billing.activity
                            .map((a) => {
                                return a.name;
                            })
                            .join(", "),
                    })}
                </div>

                <div className="flex flex-row justify-center gap-2">
                    <Button className={"btn-primary"} onClick={() => handleConfirm()} disabled={isSubmitting}>
                        {t("Yes", { ns: "button" })}
                    </Button>
                    <Button className={"btn-gray"} onClick={() => closeModal()}>
                        {t("No", { ns: "button" })}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalBillingDeleteKid;
