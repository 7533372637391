import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { DateRangePicker } from "@nextui-org/date-picker";
import { Card, CardContent, CardHeader, useTheme } from "@mui/material";
import { OptionMenu } from "components/ui";
import { IoCalendarOutline } from "react-icons/io5";
import { LoadingSpinner } from "components/common";

const ApexBarChart = ({ isLoading, isEnableRange = false, title, menuOptions, handleDateRangeChange, ...props }) => {
    const theme = useTheme();

    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState(null);

    useEffect(() => {
        if (props.series !== null) {
            setSeries(props.series);

            setOptions({
                chart: {
                    parentHeightOffset: 0,
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                },
                colors: ["#94FA46", "#ff0000"],
                dataLabels: { enabled: false },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        // barHeight: "30%",
                        // horizontal: true,
                        // startingShape: "rounded",
                        columnWidth: "30px",
                        dataLabels: {
                            total: {
                                enabled: true,
                                offsetX: 0,
                                style: {
                                    fontSize: "13px",
                                    fontWeight: 900,
                                },
                            },
                        },
                    },
                },
                grid: {
                    borderColor: theme.palette.divider,
                    xaxis: {
                        lines: { show: false },
                    },
                    padding: {
                        top: -10,
                    },
                },
                yaxis: {
                    labels: {
                        style: { colors: theme.palette.text.disabled },
                    },
                },
                xaxis: {
                    axisBorder: { show: false },
                    axisTicks: { color: theme.palette.divider },
                    categories: props.categories,
                    labels: {
                        style: { colors: theme.palette.text.disabled },
                    },
                },
                stroke: {
                    // width: 1,
                    // colors: ["#fff"],
                },
                fill: {
                    opacity: 1,
                },
                ...props,
            });
        }
    }, [isLoading]);

    return (
        <Card>
            <CardHeader
                title={title}
                sx={{
                    flexDirection: ["column", "row"],
                    alignItems: ["flex-start", "center"],
                    "& .MuiCardHeader-action": { mb: 0 },
                    "& .MuiCardHeader-content": { mb: [2, 0] },
                }}
                action={
                    isEnableRange && (
                        <DateRangePicker
                            size={"sm"}
                            onChange={handleDateRangeChange}
                            visibleMonths={2}
                            selectorIcon={<IoCalendarOutline size={"1rem"} />}
                            calendarProps={{
                                classNames: {
                                    base: "bg-background shadow",
                                    headerWrapper: "bg-background",
                                    prevButton: "border-1 border-default-200 rounded-small",
                                    nextButton: "border-1 border-default-200 rounded-small",
                                    gridHeader: "bg-background shadow-none border-b-1 border-default-100",
                                    cellButton: [
                                        "data-[today=true]:bg-default-100 data-[selected=true]:bg-transparent rounded-small",
                                        // start (pseudo)
                                        "data-[range-start=true]:before:bg-red-400 rounded-l-small",
                                        "data-[selection-start=true]:before:bg-red-400 rounded-l-small",
                                        // end (pseudo)
                                        "data-[range-end=true]:before:bg-red-400 rounded-r-small",
                                        "data-[selection-end=true]:before:bg-red-400 rounded-r-small",
                                        // start (selected)
                                        "data-[selected=true]:data-[selection-start=true]:data-[range-selection=true]:bg-primary rounded-small",
                                        // end (selected)
                                        "data-[selected=true]:data-[selection-end=true]:data-[range-selection=true]:bg-primary rounded-small",

                                        "data-[selected=true]:data-[range-selection=true]:before:bg-primary",
                                    ],
                                },
                            }}
                        />
                    )
                    // <OptionMenu
                    //     options={menuOptions}
                    //     // iconButtonProps={{ size: "small", sx: { color: "text.disabled" } }}
                    // />
                }
            />
            <CardContent>
                {isLoading || series.length <= 0 ? (
                    <LoadingSpinner />
                ) : (
                    <Chart type="bar" height={300} options={options} series={series} />
                )}
            </CardContent>
        </Card>
    );
};

export default ApexBarChart;
