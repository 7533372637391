import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ButtonSignUp = () => {
    const { t } = useTranslation();

    return (
        <div className="text-center">
            {t("Don't have an account?", { ns: "common" })}
            <Link to={"/signup"} className="text-primary ml-2">
                {t("Sign up!", { ns: "common" })}
            </Link>
        </div>
    );
};
