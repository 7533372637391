import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IoAddOutline,
    IoBugOutline,
    IoCalendarOutline,
    IoCallOutline,
    IoCreateOutline,
    IoInformationCircleOutline,
    IoLocationOutline,
    IoMailOutline,
    IoPeopleOutline,
    IoPersonOutline,
    IoRestaurantOutline,
} from "react-icons/io5";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { Alert, Box, BoxItem, Button, CallTo, ContentHeading, MailTo } from "components/ui";
import { DataEmpty, LoadingSpinner } from "components/common";
import BoxInstitutionInformation from "pages/Institution/components/BoxInstitutionInformation";
import BoxInstitutionMoreInfo from "pages/Institution/components/BoxInstitutionMoreInfo";
import InstitutionFind from "./InstitutionJoinModal";
import InstitutionJoinModal from "./InstitutionJoinModal";

function KidInstitutions({ kidId, institutions }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [activeInstitution, setActiveInstitution] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [institution, setInstitution] = useState(null);

    useEffect(() => {
        if (institutions.length) {
            setLoading(true);

            AxiosInstance({
                url: "kids/" + kidId + "/institutions/" + institutions[activeInstitution].id,
                method: "GET",
            })
                .then((response) => {
                    setInstitution(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setMessage(error.response.data.message);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, [activeInstitution]);

    const openModal = (groupId = null, type = "view") => {
        if (groupId === null) {
            setModal({ show: true, data: null, type: type });
        } else {
            setModal({ show: true, data: { groupId: groupId }, type: type });
        }
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    const handleRequestApproval = () => {
        sendRequest("accepted");
    };

    const handleRequestReject = () => {
        sendRequest("rejected");
    };

    const sendRequest = (status) => {
        setLoading(true);

        AxiosInstance({
            url: "kids/" + kidId + "/institutions/join/" + institution.joinId,
            method: "POST",
            data: { status: status },
        })
            .then((response) => {
                if ("rejected" === status) {
                    setInstitution(null);
                } else {
                    setInstitution(response.data);
                }

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setMessage(error.response.data.message);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <>
            {institutions.length > 1 ? (
                <div className="content-links">
                    {institutions.map((institution, index) => (
                        <Button
                            className={index === activeInstitution ? "btn-primary" : "btn-dark-outline btn-opacity"}
                            onClick={() => setActiveInstitution(index)}
                            key={index}
                        >
                            {institution.name}
                        </Button>
                    ))}
                </div>
            ) : (
                ""
            )}

            {isLoading ? (
                <LoadingSpinner />
            ) : institution ? (
                <>
                    {institution.joinStatus === "accepted" ? (
                        <div className={"content-inner institution"}>
                            <Box className={"!p-0"}>
                                <div className="content-inner-bg"></div>
                                <div className="content-inner-name">
                                    <div className="logo"></div>
                                    <h4>{institution.name}</h4>
                                </div>
                            </Box>

                            <ContentHeading>
                                <IoInformationCircleOutline size={"1.25em"} />
                                {t("Information", { ns: "common" })}
                            </ContentHeading>

                            <div className="flex flex-col md:flex-row gap-6">
                                <BoxInstitutionInformation institution={institution} />
                                <BoxInstitutionMoreInfo institution={institution} />
                            </div>
                        </div>
                    ) : institution.joinStatus === "awaiting_approval" && institution.joinSentBy === "parent" ? (
                        <Alert
                            type={"info"}
                            message={t(
                                "The request to join the institution has been sent. Wait for approval from the institution.",
                                { ns: "common" }
                            )}
                        />
                    ) : institution.joinStatus === "awaiting_approval" && institution.joinSentBy === "institution" ? (
                        <>
                            <Alert
                                type={"success"}
                                message={t(
                                    "The {{institution}} institution sent a request for the kid to join the institution.",
                                    {
                                        ns: "common",
                                        institution: institution.name,
                                    }
                                )}
                            />
                            <div className={"flex gap-4"}>
                                <Button className={"btn btn-primary"} onClick={handleRequestApproval}>
                                    {t("Accept", { ns: "button" })}
                                </Button>
                                <Button className={"btn btn-gray"} onClick={handleRequestReject}>
                                    {t("Reject", { ns: "button" })}
                                </Button>
                            </div>
                        </>
                    ) : institution.joinStatus === "rejected" && institution.joinSentBy === "parent" ? (
                        <>
                            <Alert
                                type={"warning"}
                                message={t("The {{institution}} institution rejected the request to add the child.", {
                                    ns: "common",
                                    institution: institution.name,
                                })}
                            />
                            <Button className={"btn btn-primary w-fit"} onClick={() => openModal(null, "join")}>
                                <IoAddOutline size={"1.125rem"} />
                                {t("Join to institution", { ns: "button" })}
                            </Button>
                        </>
                    ) : institution.joinStatus === "rejected" && institution.joinSentBy === "institution" ? (
                        <>
                            <Alert
                                type={"warning"}
                                message={t("You rejected a request to add a child to the institution.", {
                                    ns: "common",
                                })}
                            />
                            <Button className={"btn btn-primary w-fit"} onClick={() => openModal(null, "join")}>
                                <IoAddOutline size={"1.125rem"} />
                                {t("Join to institution", { ns: "button" })}
                            </Button>
                        </>
                    ) : (
                        ""
                    )}
                </>
            ) : (
                <>
                    <DataEmpty title={t("Kid's institution not found", { ns: "common" })} message={message} />

                    <Button className={"btn btn-primary w-fit"} onClick={() => openModal(null, "join")}>
                        <IoAddOutline size={"1.125rem"} />
                        {t("Join to institution", { ns: "button" })}
                    </Button>
                </>
            )}

            {modal.show && modal.type === "join" && (
                <InstitutionJoinModal kidId={kidId} closeModal={handleModalClose} />
            )}
        </>
    );
}

export default KidInstitutions;
