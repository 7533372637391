import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IoAddOutline,
    IoBugOutline,
    IoCalendarOutline,
    IoCallOutline,
    IoCreateOutline,
    IoHappyOutline,
    IoInformationCircleOutline,
    IoLocationOutline,
    IoMailOutline,
    IoPeopleOutline,
    IoPersonOutline,
    IoRestaurantOutline,
} from "react-icons/io5";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { Box, BoxItem, Button, CallTo, ContentHeading, MailTo } from "components/ui";
import { DataEmpty, LoadingSpinner } from "components/common";

function KidsList({ kids }) {
    const { t } = useTranslation();
    const [activeKid, setActiveKid] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [kid, setKid] = useState(null);

    const [parentsLoading, setParentsLoading] = useState(true);
    const [parents, setParents] = useState(null);

    useEffect(() => {
        if (kids.length) {
            setLoading(true);

            AxiosInstance({
                url: "kids/" + kids[activeKid].id,
                method: "GET",
            })
                .then((response) => {
                    setKid(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    // setMessage(error.response.data.message);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });

            AxiosInstance({
                url: "kids/" + kids[activeKid].id + "/parents",
                method: "GET",
            }).then((response) => {
                setParents(response.data);

                setTimeout(() => {
                    setParentsLoading(false);
                }, 300);
            });
        } else {
            setLoading(false);
        }
    }, [activeKid]);

    return (
        <>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnUrl={"/parent/kids/new"}
                btnIcon={<IoAddOutline size={"1.125rem"} />}
                btnLabel={t("Add kid", { ns: "button" })}
            >
                <IoHappyOutline size={"1.5rem"} />
                {t("My Kids", { ns: "common" })}
            </ContentHeading>

            {kids.length > 1 && (
                <div className="content-links">
                    {kids.map((kid, index) => (
                        <Button
                            className={index === activeKid ? "btn-primary" : "btn-dark-outline btn-opacity"}
                            onClick={() => setActiveKid(index)}
                            key={index}
                        >
                            {kid.fullName}
                        </Button>
                    ))}
                </div>
            )}

            {isLoading ? (
                <LoadingSpinner />
            ) : kid ? (
                <div className={"content-inner kid"}>
                    <ContentHeading
                        showBtn={true}
                        btnUrl={"/parent/kids/" + kid.id + "/edit"}
                        btnIcon={<IoCreateOutline size={"1.125rem"} />}
                        btnLabel={t("Edit", { ns: "button" })}
                    >
                        <IoInformationCircleOutline size={"1.5rem"} />
                        {t("Information", { ns: "common" })}
                    </ContentHeading>

                    <div className="flex flex-col md:flex-row gap-6">
                        <Box
                            className={"box-items w-full md:w-4/6"}
                            title={t("Background information", { ns: "common" })}
                        >
                            <BoxItem
                                icon={<IoPersonOutline size={"1.25em"} />}
                                label={t("Full name", { ns: "common" })}
                            >
                                {kid.fullName}
                            </BoxItem>
                            <BoxItem
                                icon={<IoCalendarOutline size={"1.25em"} />}
                                label={t("Birthday", { ns: "common" })}
                            >
                                {dayjs(kid.birthday).format("L")}
                            </BoxItem>
                            <BoxItem
                                icon={<IoLocationOutline size={"1.25em"} />}
                                label={t("Address", { ns: "common" })}
                            >
                                {kid.street ? (
                                    <>
                                        {kid.street} {kid.number}
                                        {kid.premises && "/" + kid.premises}, {kid.zipCode} {kid.city}
                                    </>
                                ) : (
                                    "---"
                                )}
                            </BoxItem>
                        </Box>
                        <Box className={"box-items w-full md:w-2/6"} title={t("Health information", { ns: "common" })}>
                            <BoxItem icon={<IoRestaurantOutline size={"1.25em"} />} label={t("Diet", { ns: "common" })}>
                                {kid.diet ? t(kid.diet.name, { ns: "common" }) : "---"}
                            </BoxItem>
                            <BoxItem icon={<IoBugOutline size={"1.25em"} />} label={t("Allergies", { ns: "common" })}>
                                {kid.allergies.length
                                    ? kid.allergies.map((a) => t(a.name, { ns: "common" })).join(", ")
                                    : "---"}
                            </BoxItem>
                        </Box>
                    </div>

                    <div className="flex gap-6">
                        <Box className={"box-items w-full"} title={t("Additional information", { ns: "common" })}>
                            <BoxItem label={t("Kid code", { ns: "common" })}>{kid.code}</BoxItem>
                            <BoxItem label={t("Consent for photos of the kid", { ns: "common" })}>
                                {kid.photoConsent ? t("yes", { ns: "common" }) : t("no", { ns: "common" })}
                            </BoxItem>
                            <BoxItem label={t("Additional information", { ns: "common" })}>
                                {kid.information ? kid.information : t("lack", { ns: "common" })}
                            </BoxItem>
                        </Box>
                    </div>

                    {kid.diagnoses.length ? (
                        <Box className={"box-items w-full"} title={t("Diagnose", { ns: "common" })}>
                            {kid.diagnoses.map((diagnose) => (
                                <div key={diagnose.id}>
                                    <pre>{JSON.stringify(diagnose.diagnose, null, 2)}</pre>
                                </div>
                            ))}
                        </Box>
                    ) : (
                        ""
                    )}

                    <div className="flex flex-col gap-6 trainers">
                        <ContentHeading
                            showBtn={false}
                            // btnUrl={"/kids/" + brand + "/trainers/new"}
                            btnIcon={<IoAddOutline size={"1.125rem"} />}
                            btnLabel={t("Add parent", { ns: "button" })}
                        >
                            <IoPeopleOutline size={"1.5rem"} />
                            {t("Parents", { ns: "common" })}
                        </ContentHeading>

                        {parentsLoading ? (
                            <LoadingSpinner />
                        ) : parents.length ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6">
                                {parents.map((parent, index) => (
                                    <Box className={"box-items"} key={index}>
                                        <BoxItem
                                            icon={<IoPersonOutline size={"1.25em"} />}
                                            label={t("Full name", { ns: "common" })}
                                        >
                                            {parent.fullName}
                                        </BoxItem>
                                        <BoxItem
                                            icon={<IoMailOutline size={"1.25rem"} />}
                                            label={t("E-mail", { ns: "common" })}
                                        >
                                            <MailTo email={parent.email}>{parent.email}</MailTo>
                                        </BoxItem>
                                        <BoxItem
                                            icon={<IoCallOutline size={"1.25rem"} />}
                                            label={t("Phone", { ns: "common" })}
                                        >
                                            <CallTo phone={parent.phone}>{parent.phone}</CallTo>
                                        </BoxItem>
                                    </Box>
                                ))}
                            </div>
                        ) : (
                            t("Parents not found", { ns: "common" })
                        )}
                    </div>
                </div>
            ) : (
                <DataEmpty title={t("You don't have any kids in the system", { ns: "common" })} />
            )}
        </>
    );
}

export default KidsList;
