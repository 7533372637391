import React from "react";

import { IconComponent } from "components/ui";
import { Avatar } from "@mui/material";

export const GroupIcon = ({ icon, color }) => {
    const style = {
        color: `${color}`,
        bgcolor: `${color}33`,
        padding: "0.5rem",
        height: 36,
        width: 36,
    };

    return (
        icon && (
            <Avatar skin="light" sx={style}>
                <IconComponent icon={icon} />
            </Avatar>
        )
    );
};
