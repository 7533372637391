import React from "react";
import { PulseLoader } from "react-spinners";

export const LoadingSpinner = ({ isFull = false, children }) => {
    return (
        <div className={"spinner-container " + (isFull ? "!min-h-screen " : "")}>
            {children}
            <PulseLoader color={"#94FA46"} speedMultiplier={0.8} />
        </div>
    );
};
