import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoAddOutline, IoCreateOutline, IoEyeOutline, IoHappyOutline, IoPeopleOutline } from "react-icons/io5";

import { Badge, ContentHeading, OptionMenu } from "components/ui";
import KidsModalView from "./KidsModalView";
import { GroupIcon } from "./GroupIcon";
import { DataGrid } from "components/grid";
import ModalKidGroup from "./ModalKidGroup";
import { LoadingSpinner } from "components/common";

function KidsList({ kids, handleLoadData }) {
    const { t } = useTranslation();
    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const group = searchParams.get("group");

    const [isLoading, setLoading] = useState(true);
    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [initialState, setInitialState] = useState(null);

    const columns = [
        { field: "fullName", headerName: t("Full name", { ns: "common" }), width: 240 },
        {
            field: "group",
            headerName: t("Group", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
            valueFormatter: (value) => `${value}`,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-4">
                        <GroupIcon icon={params.row.group.icon} color={params.row.group.color} />
                        {params.row.group.name || ""}
                    </div>
                );
            },
        },
        {
            field: "joinStatus",
            headerName: t("Status", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${value || "---"}`,
            valueFormatter: (value) => `${t(value, { ns: "common" })}`,
            renderCell: (params) => {
                let color = "";
                switch (params.row.joinStatus) {
                    case "awaiting_approval":
                        color = "#dc2626";
                        break;
                    case "accepted":
                        color = "#166534";
                        break;
                    case "rejected":
                        color = "";
                        break;
                    case "removed":
                        color = "";
                        break;
                    default:
                        break;
                }
                return (
                    <div className="flex items-center h-full">
                        <Badge color={color}>{t(params.value, { ns: "common" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "birthday",
            headerName: t("Birthday", { ns: "common" }),
            width: 180,
        },
        {
            field: "diet",
            headerName: t("Diet", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) =>
                `${row.joinStatus === "accepted" && row.diet ? t(row.diet.name, { ns: "common" }) : "---"}`,
        },
        {
            field: "allergies",
            headerName: t("Allergies", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            valueGetter: (value, row) =>
                `${
                    (row.joinStatus === "accepted" &&
                        row.allergies &&
                        row.allergies.map((allergy) => t(allergy.name, { ns: "common" })).join(", ")) ||
                    "---"
                }`,
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            disableExport: true,
            width: 120,
            renderCell: (params) => {
                let iconButtons = [
                    {
                        icon: <IoEyeOutline size={"1.25rem"} />,
                        tooltipProps: {
                            title: t("View details", { ns: "button" }),
                        },
                        menuItemProps: {
                            onClick: () => openModal(params.row),
                        },
                    },
                ];

                let options = [];
                params.row.joinStatus === "accepted" &&
                    options.push(
                        {
                            text: t("Edit", { ns: "button" }),
                            icon: <IoCreateOutline size={"1.25em"} />,
                            href: "/institution/kids/" + params.row.id + "/edit",
                        },
                        {
                            text: t("Group", { ns: "button" }),
                            icon: <IoPeopleOutline size={"1.25em"} />,
                            menuItemProps: {
                                onClick: () => openModal(params.row, "group"),
                            },
                        }
                        // {
                        //     text: t("Remove", { ns: "button" }),
                        //     icon: <IoTrashOutline size={"1.25em"} />,
                        //     menuItemProps: {
                        //         onClick: () => openModal(params.id, "delete"),
                        //     },
                        // }
                    );

                return <OptionMenu iconButtons={iconButtons} options={options} />;
            },
        },
    ];

    useEffect(() => {
        setLoading(true);
        setInitialState(null);

        if (group != null) {
            setInitialState({
                filter: {
                    filterModel: {
                        items: [{ field: "group", operator: "contains", value: group }],
                    },
                },
            });
        }

        setTimeout(() => {
            setLoading(false);
        }, 300);
    }, [group, search]);

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        handleLoadData();
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnUrl={"/institution/kids/new"}
                btnIcon={<IoAddOutline size={"1.125rem"} />}
                btnLabel={t("Add kid", { ns: "button" })}
            >
                <IoHappyOutline size={"1.5rem"} />
                {t("List of kids", { ns: "common" })}
            </ContentHeading>

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <DataGrid
                    initialState={initialState}
                    rows={kids}
                    columns={columns}
                    enableSearch={true}
                    fileName={"ListaDzieci"}
                />
            )}

            {modal.show && modal.data && modal.type === "view" && (
                <KidsModalView data={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "group" && (
                <ModalKidGroup data={modal.data} closeModal={handleModalClose} />
            )}
        </>
    );
}

export default KidsList;
