import { useTimeField } from "@mui/x-date-pickers/TimeField/useTimeField";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const FormatDate = ({ date }) => {
    const { i18n } = useTranslation();

    if (!date) return date;

    const formatting = {};

    return new Intl.DateTimeFormat(i18n.language, formatting).format(dayjs(date));
};

export const FormatTime = ({ date, formatting = { hour: "numeric", minute: "numeric", second: "numeric" } }) => {
    const { i18n } = useTranslation();

    if (!date) return date;

    // formatting.timeZone = "UTC";

    return new Intl.DateTimeFormat(i18n.language, formatting).format(dayjs(date)).toString();
};

export const FormatPrice = ({ price }) => {
    const { t } = useTranslation();

    return t("{{price, currency(PLN)}}", { price: price });
};

export const FormatMinutes = ({ minutes }) => {
    const { t } = useTranslation();

    let h = Math.floor(minutes / 60);
    let m = minutes % 60;

    let time = h > 0 ? h + (m > 0 ? ":" + m : "") : m;
    let desc = h > 0 ? t("hours", { ns: "common" }) : t("minutes", { ns: "common" });

    return (
        <>
            {time} {desc}
        </>
    );
};
