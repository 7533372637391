import React from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { Modal } from "components/ui";
import { AdvancedForm } from "components/forms/AdvancedForm";

function ModalPaymentCompleted({ orderId, closeModal, reloadData }) {
    const { t } = useTranslation();

    const formSchema = [
        {
            name: "paymentDate",
            label: t("Payment date", { ns: "common" }),
            componentType: "datePicker",
            required: true,
            value: null,
            fieldClassName: "col-span-full",
        },
    ];

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "payments/" + orderId + "/completed",
            method: "POST",
            data: values,
        })
            .then((response) => {
                setTimeout(() => {
                    setSubmitting(false);
                    closeModal();
                    reloadData();
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <Modal handleClose={closeModal} title={t("Enter the date of payment", { ns: "common" })}>
                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    gridCols={2}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonOnClick={closeModal}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            </Modal>
        </>
    );
}

export default ModalPaymentCompleted;
