import React, { useEffect, useState } from "react";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import ApexBarChart from "components/charts/ApexBarChart";

function StatisticsPaymentsCompleted() {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "dashboard/statistics/payments/completed",
            method: "GET",
        })
            .then((response) => {
                let data = {
                    colors: ["#94FA46"],
                    categories: [],
                    series: [
                        {
                            name: "Transakcje",
                            data: [],
                        },
                    ],
                };

                response.data.forEach((item) => {
                    data["categories"].push(item.year + "-" + item.month);
                    data["series"][0]["data"].push(item.qty);
                });

                setData(data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <ApexBarChart title={"Liczba transakcji"} categories={data.categories} series={data.series} {...data} />
            )}
        </>
    );
}

export default StatisticsPaymentsCompleted;
