import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { Button, Modal } from "components/ui";
import { enqueueSnackbar } from "notistack";

function InstitutionJoinModal({ kidId, closeModal }) {
    const { t } = useTranslation();

    const [isSubmitting, setSubmitting] = useState(false);
    const [data, setData] = useState(null);

    const handleChange = (input) => (e) => {
        setData((data) => {
            return {
                ...data,
                [input]: e.target.value,
            };
        });
    };

    const handleSubmit = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "kids/" + kidId + "/institutions/join",
            method: "POST",
            data: data,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Find institution", { ns: "common" })}>
            <div className="grid grid-cols-6 gap-4">
                <div className="form-field col-span-full">
                    <label htmlFor="code" className="form-label">
                        {t("Institution code", { ns: "common" })}
                    </label>
                    <input
                        type="text"
                        name="code"
                        id="code"
                        className="form-input"
                        onChange={handleChange("code")}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") handleSubmit();
                        }}
                    />
                </div>

                <Button
                    className={"btn-primary col-span-full md:col-span-2"}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? t("Sending", { ns: "button" }) : t("Save", { ns: "button" })}
                </Button>
            </div>
        </Modal>
    );
}

export default InstitutionJoinModal;
