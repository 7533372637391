import React from "react";

import { Dashboard } from "components/common";

export const HomePage = () => {
    return (
        <Dashboard>
            <div className="content"></div>
        </Dashboard>
    );
};
