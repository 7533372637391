import React, { useEffect, useState } from "react";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";

import AxiosInstance from "utils/AxiosInstance";
import EnrollmentList from "./components/EnrollmentList";

export const EnrollmentsPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [enrollments, setEnrollments] = useState(null);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        setLoading(true);

        AxiosInstance({
            url: "enrollments",
            method: "GET",
        })
            .then((response) => {
                setEnrollments(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <Dashboard contentClass={"enrollments"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : enrollments ? (
                <EnrollmentList enrollments={enrollments} handleLoadData={handleLoadData} />
            ) : (
                <AccessDenied />
            )}
        </Dashboard>
    );
};
