import React from "react";
import { useTranslation } from "react-i18next";
import { IoBusinessOutline, IoCreateOutline, IoInformationCircleOutline } from "react-icons/io5";

import { Box, ContentHeading } from "components/ui";
import { DataEmpty } from "components/common";
import BoxInstitutionInformation from "./BoxInstitutionInformation";
import BoxInstitutionMoreInfo from "./BoxInstitutionMoreInfo";
import GroupsList from "./GroupsList";

function InstitutionView({ institution }) {
    const { t } = useTranslation();

    return (
        <>
            <ContentHeading tag="h3">
                <IoBusinessOutline size={"1.5rem"} />
                {t("My institution", { ns: "common" })}
            </ContentHeading>

            {institution ? (
                <>
                    <div className={"content-inner institution"}>
                        <Box className={"!p-0"}>
                            <div className="content-inner-bg"></div>
                            <div className="content-inner-name">
                                <div className="logo"></div>
                                <h4>{institution.name}</h4>
                            </div>
                        </Box>

                        <ContentHeading
                            showBtn={true}
                            btnUrl={"/institution/edit"}
                            btnIcon={<IoCreateOutline size={"1.125rem"} />}
                            btnLabel={t("Edit", { ns: "button" })}
                        >
                            <IoInformationCircleOutline size={"1.5rem"} />
                            {t("Information", { ns: "common" })}
                        </ContentHeading>

                        <div className="flex flex-col md:flex-row gap-6">
                            <BoxInstitutionInformation institution={institution} />
                            <BoxInstitutionMoreInfo institution={institution} />
                        </div>
                    </div>

                    <GroupsList institution={institution} />
                </>
            ) : (
                <DataEmpty title={t("No institution found", { ns: "common" })} />
            )}
        </>
    );
}

export default InstitutionView;
