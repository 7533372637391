import { useLexicons } from "hooks/useLexicons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const FormSchema = () => {
    const { t } = useTranslation();
    const lexicons = useLexicons();

    const [businessTypeOptions, setBusinessTypeOptions] = useState([]);

    useEffect(() => {
        if (lexicons) {
            setBusinessTypeOptions(lexicons["business"]["type"]);
        }
    }, [lexicons]);

    return [
        {
            name: "name",
            label: t("Company name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            className: "test",
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "businessType",
            label: t("Business type", { ns: "common" }),
            componentType: "select",
            required: true,
            fieldClassName: "col-span-full md:col-span-3",
            options: businessTypeOptions,
            getOptionLabel: (option) => t(option.name, { ns: "lexicons" }),
        },
        {
            name: "vatNumber",
            label: t("VAT number", { ns: "common" }),
            componentType: "number",
            required: true,
            maxLength: 12,
            className: "test",
            fieldClassName: "col-span-full md:col-span-2",
        },
        {
            condition: {
                key: "businessType",
                value: ["LTD", "JSC", "FOUNDATION"],
                operator: "=",
            },
            name: "krsNumber",
            label: t("KRS number", { ns: "common" }),
            componentType: "number",
            required: true,
            maxLength: 12,
            className: "test",
            fieldClassName: "col-span-full md:col-span-2",
        },
        {
            name: "regonNumber",
            label: t("REGON number", { ns: "common" }),
            componentType: "number",
            required: false,
            maxLength: 12,
            className: "test",
            fieldClassName: "col-span-full md:col-span-2",
        },
        {
            name: "phone",
            label: t("Phone", { ns: "common" }),
            componentType: "phone",
            required: true,
            maxLength: 20,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "email",
            label: t("E-mail", { ns: "common" }),
            componentType: "email",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "street",
            label: t("Street", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 120,
            fieldClassName: "col-span-full md:col-span-4",
        },
        {
            name: "streetNumber",
            label: t("Number", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 10,
            fieldClassName: "col-span-3 md:col-span-1",
        },
        {
            name: "unitNumber",
            label: t("Premises number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 10,
            fieldClassName: "col-span-3 md:col-span-1",
        },
        {
            name: "postalCode",
            label: t("Zip code", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 18,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "city",
            label: t("City", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "accountNumber",
            label: t("Bank Account No.", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 26,
            validation: {
                regex: {
                    expression: /^[0-9]{26}$/,
                    message: t("The allowed characters are numbers. The required length is 26 numbers.", {
                        ns: "validation",
                    }),
                },
            },
            fieldClassName: "col-span-full",
        },
        {
            name: "activityDescription",
            label: t("Activity description", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "companyDescription",
            label: t("Company description", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "website",
            label: t("Website", { ns: "common" }),
            componentType: "url",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "socialMediaFacebook",
            label: t("Facebook", { ns: "common" }),
            componentType: "url",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "socialMediaTwitter",
            label: t("X", { ns: "common" }),
            componentType: "url",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "socialMediaYouTube",
            label: t("YouTube", { ns: "common" }),
            componentType: "url",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "socialMediaInstagram",
            label: t("Instagram", { ns: "common" }),
            componentType: "url",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "socialMediaTikTok",
            label: t("TikTok", { ns: "common" }),
            componentType: "url",
            required: false,
            fieldClassName: "col-span-full",
        },
    ];
};
