import React, { useEffect, useState } from "react";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";

import AxiosInstance from "utils/AxiosInstance";
import BillingList from "./components/BillingList";

export const BillingsPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [billings, setBillings] = useState(null);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        AxiosInstance({
            url: "billings",
            method: "GET",
        })
            .then((response) => {
                setBillings(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <Dashboard contentClass={"billings"}>
            {isLoading ? <LoadingSpinner /> : <BillingList billings={billings} handleLoadData={handleLoadData} />}
        </Dashboard>
    );
};
