import React from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "components/common/Logo";

export const Maintenance = () => {
    const { t } = useTranslation();

    return (
        <div className="h-screen w-full flex flex-col justify-center items-center">
            <Logo />
            <h3 className="mb-4">{t("We are under maintenance currently!", { ns: "common" })}</h3>
        </div>
    );
};
