import React from "react";
import { GridToolbar, DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";

import dayjs from "dayjs";

export const DataGrid = ({ rows, columns, enableSearch = false, staticHeight, openModal, ...props }) => {
    let currentTime = dayjs().format("YYYY-MM-DD-HH-mm");

    return (
        <div
            className="table-box"
            style={{
                flex: "1 1 auto",
                height: staticHeight && "420px",
            }}
        >
            <MuiDataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    ...props.initialState,
                    pagination: {
                        paginationModel: { pageSize: 25 },
                    },
                }}
                pageSizeOptions={[15, 25, 50, 100]}
                checkboxSelection
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
                disableColumnResize
                width={"100%"}
                slots={{
                    toolbar: enableSearch && GridToolbar,
                    columnSortedDescendingIcon: IoChevronUpOutline,
                    columnSortedAscendingIcon: IoChevronDownOutline,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        printOptions: {
                            disableToolbarButton: true,
                            // hideFooter: true,
                            // hideToolbar: true,
                            // pageStyle: ".MuiDataGrid-root .MuiDataGrid-main { font-size: 12; }",
                        },
                        csvOptions: {
                            delimiter: ";",
                            utf8WithBom: true,
                            fileName: (props.fileName ? "TheKidly-" + props.fileName : "TheKidly") + "-" + currentTime,
                        },
                    },
                }}
                sx={{
                    "& .MuiDataGrid-toolbarQuickFilter": {
                        backgroundColor: "rgb(229 231 235 / var(--tw-bg-opacity))",
                    },
                    "& .MuiDataGrid-toolbarQuickFilter input": {
                        maxWidth: "140px",
                    },
                    "& .MuiDataGrid-cell": {
                        borderColor: "#9ca3af",
                    },
                    "& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
                        borderBottom: "1px solid #9ca3af",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderColor: "#9ca3af",
                    },
                    "& .MuiDataGrid-footerContainer p": {
                        margin: 0,
                    },
                }}
                {...props}
            />
        </div>
    );
};
