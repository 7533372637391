import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/ui";
import { PasswordUpdateForm } from "./PasswordUpdateForm";

function UpdatePasswordModal() {
    const { t } = useTranslation();

    return (
        <Modal title={t("Set your access password", { ns: "common" })} hideCloseButton={true}>
            <PasswordUpdateForm isModal={true} />
        </Modal>
    );
}

export default UpdatePasswordModal;
