import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { Menu, Navbar, Footer } from "components/layout";
import { Button } from "components/ui";
import CurrentUser from "services/CurrentUser";

export const Dashboard = ({ contentClass, children }) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const showMenu = useMediaQuery(theme.breakpoints.up("xl"));

    const currentUser = CurrentUser();

    const handleStopImpersonating = () => {
        sessionStorage.setItem("user", null);
        sessionStorage.removeItem("impersonatingUid");
        window.location = "/admin/users";
    };

    let dashboardClass = currentUser.roles.includes("ROLE_ADMIN") ? "admin" : currentUser.accountType;
    dashboardClass += sessionStorage.getItem("impersonatingUid") ? " impersonating pt-12" : "";

    return (
        <>
            {sessionStorage.getItem("impersonatingUid") && (
                <div className="fixed w-full h-12 bg-red-400 z-50 flex justify-center items-center">
                    <Button className="btn-sm btn-black" onClick={() => handleStopImpersonating()}>
                        {t("Stop Impersonating", { ns: "button" })}
                    </Button>
                </div>
            )}

            <div className={"dashboard " + dashboardClass}>
                {showMenu ? <Menu /> : null}

                <div className={"dashboard-container"}>
                    <Navbar />

                    <div className={"content " + contentClass}>{children}</div>

                    <Footer />
                </div>
            </div>
        </>
    );
};
