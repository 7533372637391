import React from "react";
import { Avatar as MuiAvatar } from "@mui/material";

import { Initials } from "utils/Initials";

export const Avatar = ({ avatar, size = 38 }) => {
    const { avatarAlt, avatarImg, avatarIcon, avatarText, avatarColor } = avatar;

    const style = {
        bgcolor: avatarColor,
        fontSize: Math.round(size / 2.125) + "px",
        height: size,
        width: size,
    };

    if (avatarImg) {
        return (
            <MuiAvatar alt={avatarAlt} src={avatarImg} sx={style}>
                {Initials(avatarText)}
            </MuiAvatar>
        );
    } else if (avatarIcon) {
        return (
            <MuiAvatar skin="light" sx={style}>
                {avatarIcon}
            </MuiAvatar>
        );
    } else {
        return (
            <MuiAvatar skin="light" sx={style}>
                {Initials(avatarText)}
            </MuiAvatar>
        );
    }
};
