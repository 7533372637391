import React, { useEffect, useState } from "react";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import ApexBarChart from "components/charts/ApexBarChart";

function StatisticsParent() {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "dashboard/statistics/users/parent",
            method: "GET",
        })
            .then((response) => {
                let data = {
                    colors: ["#9b9b9b", "#94FA46"],
                    categories: [],
                    series: [
                        {
                            name: "Stali klienci",
                            data: [],
                        },
                        {
                            name: "Nowi klienci",
                            data: [],
                        },
                    ],
                };

                response.data.forEach((item) => {
                    data["categories"].push(item.year + "-" + item.month);
                    data["series"][0]["data"].push(item.current);
                    data["series"][1]["data"].push(item.qty);
                });

                setData(data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <ApexBarChart
                    title={"Liczba obsługiwanych rodziców"}
                    categories={data.categories}
                    series={data.series}
                    {...data}
                />
            )}
        </>
    );
}

export default StatisticsParent;
