import { useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Box } from "@mui/system";

import { useSessionStorage } from "hooks/useSessionStorage";
import AxiosInstance from "utils/AxiosInstance";
import { Avatar } from "@mui/material";
// import { Avatar } from "components/ui";

export const ProfileAvatar = () => {
    const { t } = useTranslation();

    const [user, setUser] = useSessionStorage("user", null);

    const [isSubmitting, setSubmitting] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const handleAvatarChange = (e) => {
        setSubmitting(true);

        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);

        AxiosInstance({
            url: "users/avatar/update",
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                setUser(response.data.data);

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setInputValue("");
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                {/* <Avatar avatar={user.avatar} size={100} /> */}
                <Avatar src={user ? user.avatar.avatarImg : ""} sx={{ width: 100, height: 100 }} />

                <label className={"btn btn-primary"} disabled={isSubmitting} htmlFor="account-settings-upload-image">
                    {isSubmitting ? t("Saving", { ns: "button" }) : t("Edit photo", { ns: "button" })}
                    <input
                        hidden
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={handleAvatarChange}
                        value={inputValue}
                        id="account-settings-upload-image"
                    />
                </label>
            </Box>
        </>
    );
};
