import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { plPL as gridPlPL } from "@mui/x-data-grid/locales";

export const ThemeComponent = ({ children }) => {
    const theme = createTheme(
        {
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 640,
                    md: 768,
                    lg: 1024,
                    xl: 1280,
                    "2xl": 1536,
                },
            },
            typography: {
                fontFamily: "Plus Jakarta Sans",
            },
            palette: {
                primary: {
                    main: "#000",
                    light: "#94FA46",
                    contrastText: "#313333",
                },
                text: {
                    primary: "#000",
                    secondary: "#000",
                },
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontSize: "1rem",
                            borderRadius: "1.5rem",
                            padding: "0.75rem 1.5rem",
                            lineHeight: 1,
                            boxShadow: "none",
                            textTransform: "none",
                        },
                        sizeSmall: {
                            padding: "0.75rem 1rem",
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: "var(--color-gray-400)",
                        },
                        root: {
                            backgroundColor: "var(--color-gray-200)",
                            color: "var(--color-text)",
                            borderRadius: "0.375rem",
                            fontSize: "0.875rem",
                        },
                    },
                },
            },
            mixins: {
                MuiDataGrid: {
                    // Headers, and top & bottom fixed rows
                    containerBackground: "#e5e7eb",
                },
            },
        },
        gridPlPL
    );

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
