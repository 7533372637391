import React, { useEffect } from "react";

import { Dashboard } from "components/common";
import Grid from "@mui/material/Grid2";
import StatisticsGmv from "./components/StatisticsGmv";
import StatisticsParent from "./components/StatisticsParent";
import StatisticsInstitution from "./components/StatisticsInstitution";
import StatisticsBrand from "./components/StatisticsBrand";
import StatisticsPaymentsCompleted from "./components/StatisticsPaymentsCompleted";

export const StatisticsPage = () => {
    useEffect(() => {}, []);

    return (
        <Dashboard>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <StatisticsGmv />
                </Grid>
                <Grid size={12}>
                    <StatisticsPaymentsCompleted />
                </Grid>
                <Grid size={12}>
                    <StatisticsParent />
                </Grid>
                <Grid size={{ sx: 12, md: 6 }}>
                    <StatisticsInstitution />
                </Grid>
                <Grid size={{ sx: 12, md: 6 }}>
                    <StatisticsBrand />
                </Grid>
                {/* <Grid item xs={12} lg={6}>
                    <Chart options={payments.options} series={payments.series} type="bar" />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardStatsWithAreaChart
                        stats="97.5k"
                        chartColor="success"
                        avatarColor="success"
                        title="Revenue Generated"
                        avatarIcon="tabler:credit-card"
                        chartSeries={[{ data: [6, 35, 25, 61, 32, 84, 70] }]}
                    />
                </Grid> */}
            </Grid>
        </Dashboard>
    );
};
