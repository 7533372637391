import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import { useSessionStorage } from "hooks/useSessionStorage";
import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";

export const PasswordUpdateForm = ({ isModal = false }) => {
    const { t } = useTranslation();

    const [user, setUser] = useSessionStorage("user", null);

    let formSchema = [
        {
            name: "password",
            label: t("Password", { ns: "common" }),
            componentType: "password",
            required: true,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "confirmPassword",
            label: t("Confirmation Password", { ns: "common" }),
            componentType: "password",
            required: true,
            fieldClassName: "col-span-full md:col-span-3",
        },
    ];

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "users/password/update",
            method: "PUT",
            data: values,
        })
            .then((response) => {
                resetForm();
                setUser(response.data.data);

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    if (isModal === true) {
                        window.location.reload();
                    }

                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <AdvancedForm
            schema={formSchema}
            onSubmit={handleSubmit}
            buttonLabel={t("Save", { ns: "button" })}
            submittingButtonLabel={t("Saving", { ns: "button" })}
            buttonClassName={isModal ? "xl:col-span-2" : ""}
        />
    );
};
