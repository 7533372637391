import { useTranslation } from "react-i18next";
import { AddressSchema } from "./AddressSchema";

export const InstitutionTeacherSchema = () => {
    const { t } = useTranslation();

    return [
        {
            name: "firstName",
            label: t("First name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "lastName",
            label: t("Last name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "email",
            label: t("E-mail", { ns: "common" }),
            componentType: "email",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "phone",
            label: t("Phone", { ns: "common" }),
            componentType: "phone",
            required: true,
            maxLength: 9,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "accountNumber",
            label: t("Bank Account No.", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 26,
            validation: {
                regex: {
                    expression: /^[0-9]{26}$/,
                    message: t("The allowed characters are numbers. The required length is 26 numbers.", {
                        ns: "validation",
                    }),
                },
            },
            fieldClassName: "col-span-full",
        },
        {
            name: "address",
            componentType: "array",
            schema: AddressSchema(),
        },
        {
            name: "description",
            label: t("Description", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "qualifications",
            label: t("Qualifications", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
    ];
};
