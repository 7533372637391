import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IoAddOutline, IoCreateOutline, IoEyeOutline, IoPeopleOutline, IoTrashOutline } from "react-icons/io5";

import { ContentHeading, OptionMenu } from "components/ui";
import { DataGrid } from "components/grid";
import TeacherModalView from "./TeacherModalView";
import TeacherModalGroup from "./TeacherModalGroup";
import TeacherModalDelete from "./TeacherModalDelete";

function TeachersList({ teachers, handleLoadData }) {
    const { t } = useTranslation();
    const [modal, setModal] = useState({ show: false, data: null, type: null });

    const columns = [
        { field: "fullName", headerName: t("Full name", { ns: "common" }), width: 240 },
        {
            field: "phone",
            headerName: t("Phone", { ns: "common" }),
            width: 180,
        },
        {
            field: "group",
            headerName: t("Group", { ns: "common" }),
            width: 240,
            valueGetter: (value, row) =>
                `${(row.groups && row.groups.map((group) => group.groupName).join(", ")) || "---"}`,
        },
        {
            field: "role",
            headerName: t("Role", { ns: "common" }),
            minWidth: 240,
            flex: 1,
            valueGetter: (value, row) =>
                `${(row.groups && row.groups.map((group) => t(group.typeName, { ns: "common" })).join(", ")) || "---"}`,
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                let iconButtons = [
                    {
                        icon: <IoEyeOutline size={"1.25rem"} />,
                        tooltipProps: {
                            title: t("View details", { ns: "button" }),
                        },
                        menuItemProps: {
                            onClick: () => openModal(params.id, "view"),
                        },
                    },
                ];

                let options = [
                    {
                        text: t("Edit", { ns: "button" }),
                        icon: <IoCreateOutline size={"1.25em"} />,
                        href: "/institution/teachers/" + params.id + "/edit",
                    },
                    {
                        text: t("Groups", { ns: "button" }),
                        icon: <IoPeopleOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.id, "group"),
                        },
                    },
                    {
                        text: t("Remove", { ns: "button" }),
                        icon: <IoTrashOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.id, "delete"),
                        },
                    },
                ];

                return <OptionMenu iconButtons={iconButtons} options={options} />;
            },
        },
    ];

    const openModal = (teacherId, type = "view") => {
        setModal({ show: true, data: { teacherId: teacherId }, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnUrl={"/institution/teachers/new"}
                btnIcon={<IoAddOutline size={"1.125rem"} />}
                btnLabel={t("Add teacher", { ns: "button" })}
            >
                <IoPeopleOutline size={"1.5rem"} />
                {t("List of teachers", { ns: "common" })}
            </ContentHeading>

            <DataGrid rows={teachers} columns={columns} />

            {modal.show && modal.data && modal.type === "view" && (
                <TeacherModalView data={modal.data} closeModal={handleModalClose} />
            )}
            {modal.show && modal.data && modal.type === "group" && (
                <TeacherModalGroup data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}
            {modal.show && modal.data && modal.type === "delete" && (
                <TeacherModalDelete data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}
        </>
    );
}

export default TeachersList;
