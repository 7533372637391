import { useTranslation } from "react-i18next";
import { Drawer, IconButton, Typography } from "@mui/material";
import { IoCalendarOutline, IoCloseOutline, IoLocationOutline } from "react-icons/io5";
import { FormatDate, FormatTime } from "utils/Format";

export const AddEventSidebar = ({ store, addEventSidebarOpen, addEventSidebarWidth, handleAddEventSidebarToggle }) => {
    const { t } = useTranslation();

    const event =
        store.selectedEvent !== null && store.selectedEvent.id.length
            ? store.events.find((e) => e.id === parseInt(store.selectedEvent.id))
            : null;

    return (
        <Drawer
            anchor="right"
            open={addEventSidebarOpen}
            onClose={handleAddEventSidebarToggle}
            ModalProps={{ keepMounted: true }}
            sx={{ "& .MuiDrawer-paper": { width: ["100%", addEventSidebarWidth] } }}
        >
            <div className="flex justify-between border-b p-4">
                <Typography variant="h6">
                    {/* {event !== null && event.title.length
                        ? t("Update Event", { ns: "common" })
                        : t("Add Event", { ns: "common" })} */}
                </Typography>
                <IconButton
                    size="small"
                    onClick={handleAddEventSidebarToggle}
                    sx={{
                        p: "0.375rem",
                        borderRadius: 10,
                        color: "text.primary",
                        // backgroundColor: "action.selected",
                        "&:hover": {
                            // backgroundColor: (theme) => `rgba(${theme.palette.customColors.main}, 0.16)`,
                        },
                    }}
                >
                    <IoCloseOutline />
                </IconButton>
            </div>
            <div className="flex flex-col p-4">
                {event !== null && event.title.length ? (
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity", { ns: "common" })}:</strong>
                            {event.title}
                        </div>
                        <div className="flex items-center gap-2">
                            <IoCalendarOutline size={"1.25rem"} />
                            <FormatTime date={event.start} formatting={{ hour: "numeric", minute: "numeric" }} />
                            {" - "}
                            <FormatTime date={event.end} formatting={{ hour: "numeric", minute: "numeric" }} />
                            {" - "}
                            <FormatDate date={event.start} />
                        </div>
                        <div className="flex items-center gap-2">
                            <IoLocationOutline size={"1.25rem"} />
                            {/* <div>
                                <strong>{event.data.institution.name}</strong>
                                <br />
                                {event.data.institution.street} {event.data.institution.number}
                                <br />
                                {event.data.institution.zipCode} {event.data.institution.city}
                            </div> */}
                        </div>
                    </div>
                ) : null}
            </div>
        </Drawer>
    );
};
