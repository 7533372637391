import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";

import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";
import { SelectField } from "components/forms";
import { getInitialValues } from "components/forms/helpers";

function ModalKidGroup({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [groupsOptions, setGroupsOptions] = useState([]);

    const defaultValues = {
        group: null,
    };

    const validationSchema = Yup.object().shape({
        group: Yup.object().required(
            t("The {{label}} field is required.", {
                ns: "validation",
                label: t("Group", { ns: "common" }),
            })
        ),
    });

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "institutions/" + currentUser.institution.id + "/groups",
                method: "GET",
            }).then((response) => {
                setGroupsOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [currentUser, data]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/kids/" + data.id + "/groups",
            method: "PUT",
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    closeModal();
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Select a kid group", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : data ? (
                <Formik
                    initialValues={getInitialValues(defaultValues, data)}
                    // initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                        return (
                            <Form>
                                <div className="flex flex-col gap-4 w-full">
                                    <div className="flex flex-col gap-2">
                                        <Field
                                            component={SelectField}
                                            key={"group"}
                                            name={"group"}
                                            label={t("Group", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-2"}
                                            options={groupsOptions}
                                        />
                                    </div>

                                    <div className="flex flex-row justify-center gap-2">
                                        <Button
                                            type={"submit"}
                                            className={"btn-primary"}
                                            onClick={handleSubmit}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
}

export default ModalKidGroup;
