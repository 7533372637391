import React from "react";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import interactionPlugin from "@fullcalendar/interaction";
import plLocale from "@fullcalendar/core/locales/pl";

export const Calendar = ({
    calendarRef,
    store,
    handleLeftSidebarToggle,
    handleEventPopUpToggle,
    handleAddEventSidebarToggle,
}) => {
    const calendarOptions = {
        events: store.events,
        locale: plLocale,
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrap5Plugin],
        initialView: "dayGridMonth",
        // height: "100%",
        headerToolbar: false,
        views: {
            week: {
                titleFormat: { year: "numeric", month: "long", day: "numeric" },
            },
        },
        eventTimeFormat: {
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
        },
        eventDisplay: "block",
        dayMaxEvents: 3,
        navLinks: true,
        customButtons: {
            sidebarToggle: {
                icon: "bi bi-list",
                click() {
                    handleLeftSidebarToggle();
                },
            },
        },
        eventClick: (event) => {
            store.selectedEvent = event.event;
            handleEventPopUpToggle();
        },
    };

    return <FullCalendar ref={calendarRef} {...calendarOptions} />;
};
