import React from "react";

export const Box = ({ className = "", children, title = null, ...props }) => {
    return (
        <div className={"box " + className} {...props}>
            {title && <h6 className="font-normal uppercase">{title}</h6>}
            {children}
        </div>
    );
};
