import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCloseOutline, IoDiamondOutline, IoReloadOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatPrice } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Badge, Modal, OptionMenu } from "components/ui";
import { DataGrid } from "components/grid";
import ModalCancelPayment from "./ModalCancelPayment";
import ModalFreePayment from "./ModalFreePayment";

function ModalBillingPayments({ billing, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [modal, setModal] = useState({ show: false, data: null });
    const [payments, setPayments] = useState([]);

    const columns = [
        {
            field: "paymentId",
            headerName: t("Payment ID", { ns: "common" }),
            width: 120,
            valueGetter: (value, row) => `${value || "---"}`,
        },
        {
            field: "dueDate",
            headerName: t("Due date", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                return <FormatDate date={params.row.dueDate} />;
            },
        },
        {
            field: "paymentDate",
            headerName: t("Payment date", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                return <FormatDate date={params.row.paymentDate} />;
            },
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                let color = "";
                switch (params.row.status.value) {
                    case "NEW":
                        color = "#0284c7";
                        break;
                    case "PENDING":
                        color = "#ca8a04";
                        break;
                    case "COMPLETED":
                        color = "#166534";
                        break;
                    case "CANCELLED":
                    case "REJECTED":
                        color = "#313333";
                        break;
                    case "FAILED":
                        color = "#dc2626";
                        break;
                    case "FREE":
                        color = "#166534";
                        break;
                    default:
                        color = "#313333";
                        break;
                }

                return (
                    <div className="flex items-center h-full">
                        <Badge color={color}>{t(params.row.status.name, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "period",
            headerName: t("Period", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <FormatDate date={params.row.period.start} />
                        <span> - </span>
                        <FormatDate date={params.row.period.end} />
                    </>
                );
            },
        },
        {
            field: "amount",
            headerName: t("Amount", { ns: "common" }),
            width: 100,
            renderCell: (params) => {
                return params.row.status.value === "FREE" ? "---" : <FormatPrice price={params.value} />;
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 80,
            renderCell: (params) => {
                let options = [];

                (params.row.status.value === "NEW" ||
                    ((params.row.status.value === "CANCELLED" || params.row.status.value === "REJECTED") &&
                        params.row.required === true) ||
                    params.row.status.value === "FAILED") &&
                    options.push(
                        {
                            text: t("Set a free period", { ns: "button" }),
                            icon: <IoDiamondOutline size={"1.25em"} />,
                            tooltipProps: {
                                title: t("Set a free period and generate a new one payment for the next period", {
                                    ns: "common",
                                }),
                            },
                            menuItemProps: {
                                onClick: () => openModal(params.row.orderId, "freePayment"),
                            },
                        },
                        {
                            text: t("Cancel payment", { ns: "button" }),
                            icon: <IoCloseOutline size={"1.25em"} />,
                            tooltipProps: {
                                title: t("Cancel the payment and generate a new one for the next period", {
                                    ns: "common",
                                }),
                            },
                            menuItemProps: {
                                onClick: () => openModal(params.row.orderId, "cancelPayment"),
                            },
                        }
                    );

                (((params.row.status.value === "CANCELLED" || params.row.status.value === "REJECTED") &&
                    params.row.required === true) ||
                    params.row.status.value === "FAILED") &&
                    options.push({
                        text: t("Refresh payment status", { ns: "button" }),
                        icon: <IoReloadOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => handleRefreshStatusSubmit(params.row.orderId),
                            disabled: isSubmitting,
                        },
                    });

                params.row.invoices.length > 0 &&
                    options.push({
                        pdf: 1,
                        props: {
                            invoiceSlug: params.row.invoices[0].slug,
                            invoiceFileName: params.row.invoices[0].fileName,
                        },
                    });

                return <OptionMenu options={options} />;
            },
        },
    ];

    useEffect(() => {
        loadData();
    }, [billing]);

    const loadData = () => {
        setLoading(true);

        AxiosInstance({
            url: "billings/" + billing.id + "/payments",
            method: "GET",
        })
            .then((response) => {
                setPayments(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    const handleRefreshStatusSubmit = async (orderId) => {
        setSubmitting(true);

        AxiosInstance({
            url: "payments/refresh-status",
            method: "POST",
            data: {
                orderId: orderId,
            },
        })
            .then((response) => {
                loadData(billing);

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <Modal handleClose={closeModal} title={t("Information about payments", { ns: "common" })} big={true}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <DataGrid
                        rows={payments}
                        columns={columns}
                        staticHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        disableColumnSorting
                    />
                )}
            </Modal>

            {modal.show && modal.data && modal.type === "cancelPayment" && (
                <ModalCancelPayment orderId={modal.data} closeModal={handleModalClose} reloadData={loadData} />
            )}

            {modal.show && modal.data && modal.type === "freePayment" && (
                <ModalFreePayment orderId={modal.data} closeModal={handleModalClose} reloadData={loadData} />
            )}
        </>
    );
}

export default ModalBillingPayments;
