import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoAccessibilityOutline, IoEyeOutline } from "react-icons/io5";

import { DisplayDateTime, DisplayDayTime } from "utils/DisplayDate";
import { FormatMinutes, FormatPrice } from "utils/Format";
import { LowestPrice } from "utils/LowestPrice";
import { Button, ContentHeading, OptionMenu } from "components/ui";
import { ModalView } from "./ModalView";
import { ModalEnroll } from "./ModalEnroll";
import { DataGrid } from "components/grid";

export const SearchResult = ({ data, activities }) => {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ show: false, data: null, type: null });

    const columns = [
        { field: "name", headerName: t("Activity", { ns: "common" }), width: 320 },
        {
            field: "brand",
            headerName: t("Brand", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "price",
            headerName: t("Price from", { ns: "common" }),
            width: 180,
            sortable: false,
            renderCell: (params) => {
                return params.row.isFree ? (
                    t("Free", { ns: "common" })
                ) : (
                    <FormatPrice price={LowestPrice(params.row.billingMethods)} />
                );
            },
        },
        {
            field: "length",
            headerName: t("Activity length", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return <FormatMinutes minutes={params.row.activityLength} />;
            },
        },
        {
            field: "date",
            headerName: t("Date of activity", { ns: "common" }),
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                return params.row.period.value === "SINGLE" ? (
                    <>
                        {params.row.date
                            .map((d, index) => {
                                return <DisplayDateTime date={d.day} time={d.time} key={index} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                ) : (
                    <>
                        {params.row.date
                            .map((d, index) => {
                                return t(d.day.name, { ns: "lexicons" });
                            })
                            .filter((value, index, self) => self.indexOf(value) === index)
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                return (
                    <OptionMenu
                        iconButtons={[
                            {
                                icon: <IoEyeOutline size={"1.25rem"} />,
                                tooltipProps: {
                                    title: t("View details", { ns: "button" }),
                                },
                                menuItemProps: {
                                    onClick: () => openModal(params.row, "view"),
                                },
                            },
                            {
                                icon: <IoAccessibilityOutline size={"1.25rem"} />,
                                tooltipProps: {
                                    title: t("Enroll in an activity", { ns: "button" }),
                                },
                                menuItemProps: {
                                    onClick: () => openModal(params.row, "enroll"),
                                },
                            },
                        ]}
                    />
                );
            },
        },
    ];

    useEffect(() => {}, [searchParams]);

    const openModal = (activity, type = "view") => {
        setModal({ show: true, data: { ...data, activity: activity }, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: { ...data, activity: null }, type: null });
    };

    return (
        <>
            <ContentHeading tag="h3">{t("Search results", { ns: "common" })}</ContentHeading>

            <DataGrid rows={activities} columns={columns} />

            {modal.show && modal.data && modal.type === "view" && (
                <ModalView data={modal.data} openModal={openModal} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "enroll" && (
                <ModalEnroll data={modal.data} closeModal={handleModalClose} />
            )}
        </>
    );
};
