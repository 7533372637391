import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { Box, Button, ContentHeading } from "components/ui";
import { getInitialValues } from "components/forms/helpers";
import { TextArea, TextField, SelectField, PriceField, DatePicker, TimePicker, NumberField } from "components/forms";
import { Checkbox, FormControlLabel } from "@mui/material";
import { LoadingSpinner } from "components/common";
import { useLexicons } from "hooks/useLexicons";

function ActivityForm({ brandId, activity }) {
    const { t } = useTranslation(["validation"]);
    const lexicons = useLexicons();

    const [isLoading, setLoading] = useState(true);
    const [activityCategoriesOptions, setActivityCategoriesOptions] = useState([]);
    const [activityTypesOptions, setActivityTypesOptions] = useState([]);
    const [activityCityOptions, setActivityCityOptions] = useState([]);
    const [activityForWhomOptions, setActivityForWhomOptions] = useState([]);
    const [activityPeriodOptions, setActivityPeriodOptions] = useState([]);

    const defaultValues = {
        name: "",
        category: null,
        type: null,
        city: null,
        period: null,
        date: [
            {
                day: null,
                time: null,
            },
        ],
        forWhom: "",
        billingMethods: [],
        freeActivity: null,
        activityLength: null,
        territory: "",
        description: "",
    };

    useEffect(() => {
        if (lexicons) {
            setActivityForWhomOptions(lexicons["activity"]["forWhom"]);
            setActivityPeriodOptions(lexicons["activity"]["period"]);
        }

        Promise.all([
            AxiosInstance({
                url: "activity-cities",
                method: "GET",
            }).then((response) => {
                setActivityCityOptions(response.data);
            }),

            AxiosInstance({
                url: "activity-categories",
                method: "GET",
            }).then((response) => {
                setActivityCategoriesOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });

        if (activity && activity.category) {
            loadTypeByCategory(activity.category.id);
        }
    }, [activity, lexicons]);

    function loadTypeByCategory(categoryId) {
        AxiosInstance({
            url: "activity-types/" + categoryId,
            method: "GET",
        }).then((response) => {
            setActivityTypesOptions(response.data);
        });
    }

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "brands/" + brandId + "/activities";
        let method = "POST";

        if (activity) {
            url = "brands/" + brandId + "/activities/" + activity.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!activity && response.status === 201) {
                    resetForm({ values: defaultValues });
                }
                resetForm({ values: response.data.data });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    function getBillingMethods(forWhom, period) {
        const whoPay = lexicons["enrollment"]["whoPay"];
        let bM = [];

        if (forWhom && forWhom.value === "PARENT") {
            lexicons["enrollment"]["billingMethod"]["PAY_PARENT"].map((billingMethod) => {
                if (period.value === "SINGLE" && !billingMethod.value.includes("ACTIVITY")) {
                    return null;
                }

                return bM.push({
                    billingMethod: billingMethod,
                    active: false,
                    price: null,
                    minGroupSize: null,
                    maxGroupSize: null,
                    whoPay: whoPay.filter((wp) => {
                        return wp.value === "PAY_PARENT";
                    })[0],
                });
            });
        } else if (forWhom && forWhom.value === "INSTITUTION") {
            for (const element in lexicons["enrollment"]["billingMethod"]) {
                lexicons["enrollment"]["billingMethod"][element].map((billingMethod) => {
                    if (period.value === "SINGLE" && !billingMethod.value.includes("ACTIVITY")) {
                        return null;
                    }

                    return bM.push({
                        billingMethod: billingMethod,
                        active: false,
                        price: null,
                        minGroupSize: null,
                        maxGroupSize: null,
                        whoPay: whoPay.filter((wp) => {
                            return wp.value === element;
                        })[0],
                    });
                });
            }
        }

        return bM;
    }

    function renderBillingMethodDescription(value) {
        switch (value) {
            case "PARENT_PAY_PER_MONTH":
                return "Rodzic zapisuje dziecko na zajęcia oferowane przez placówkę. Płaci z góry ustaloną kwotę za cały miesiąc.";
            case "PARENT_PAY_PER_ACTIVITY":
                return "Rodzic zapisuje dziecko na zajęcia oferowane przez placówkę. Płaci za zajęcia z góry za cały miesiąc. System wylicza ile zajęć będzie w miesiącu i mnoży przez ustaloną cenę.";
            case "INSTITUTION_PAY_PER_MONTH":
                return "Cena z góry jest ustalona i dotyczy jednego terminu w tygodniu, jeśli użytkownik wybierze więcej terminów zajęć w tygodniu to kwota zostanie pomnożona przez ilość wybranych terminów. Faktura generowana jest na koniec miesiąca.";
            case "INSTITUTION_PAY_PER_ACTIVITY":
                return "Cena dotyczy jednych zajęć, system liczy ile zajęć odbyło się w miesiącu i mnoży to przez ustaloną cenę. Faktura generowana jest na koniec miesiąca.";
            case "INSTITUTION_PAY_PER_KID":
                return "Cena zostanie pomnożona przez ilość dzieci zapisanych na zajęcia. Faktura generowana jest na koniec miesiąca.";
            case "INSTITUTION_PAY_PER_KID_ACTIVITY":
                return "System sumuje wszystkie obecności dziecka na zajęciach w miesiącu. Faktura generowana jest na koniec miesiąca.";
            default:
                return "";
        }
    }

    function getPriceLabel(billingMethod) {
        return billingMethod === "INSTITUTION_PAY_PER_MONTH" || billingMethod === "PARENT_PAY_PER_MONTH"
            ? t("Price per month", { ns: "common" })
            : billingMethod === "INSTITUTION_PAY_PER_KID"
            ? t("Price per kid", { ns: "common" })
            : t("Price per activity", { ns: "common" });
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, t("Field too short. Minimum number of characters: {{min}}.", { ns: "validation", min: 3 }))
            .max(255, t("Field too long. Maximum number of characters: {{max}}.", { ns: "validation", max: 255 }))
            .required(t("The {{label}} field is required.", { ns: "validation", label: t("Name", { ns: "common" }) })),
        category: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Category", { ns: "common" }) })
        ),
        type: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Type", { ns: "common" }) })
        ),
        city: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("City", { ns: "common" }) })
        ),
        period: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Period", { ns: "common" }) })
        ),
        forWhom: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("For whom", { ns: "common" }) })
        ),
        freeActivity: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Free activity", { ns: "common" }) })
        ),
        date: Yup.object().when("period", {
            is: (period) => period && period.value === "SINGLE",
            then: () =>
                Yup.array(
                    Yup.object().shape({
                        day: Yup.date().required(
                            t("The {{label}} field is required.", {
                                ns: "validation",
                                label: t("Date of activity", { ns: "common" }),
                            })
                        ),
                        time: Yup.date().required(
                            t("The {{label}} field is required.", {
                                ns: "validation",
                                label: t("Hour", { ns: "common" }),
                            })
                        ),
                    })
                ),
            otherwise: () => Yup.array(),
        }),
        // billingMethods: Yup.object().when("forWhom", {
        //     is: (forWhom) => forWhom && forWhom.value === "INSTITUTION",
        //     then: () =>
        //         Yup.object()
        //             .test("billingMethodTest", null, (obj) => {
        //                 obj.map((o) => {
        //                     console.log(o);
        //                     if (o.active === true) {
        //                         return true;
        //                     }

        //                     return false;
        //                 });

        //                 return new Yup.ValidationError("Please check at least one checkbox", null, "myCustomFieldName");
        //             })
        //             .shape({
        //                 // active: Yup.boolean().oneOf([true]),
        //                 // day: Yup.date().required(
        //                 //     t("The {{label}} field is required.", {
        //                 //         ns: "validation",
        //                 //         label: t("Date of activity", { ns: "common" }),
        //                 //     })
        //                 // ),
        //                 // time: Yup.date().required(
        //                 //     t("The {{label}} field is required.", {
        //                 //         ns: "validation",
        //                 //         label: t("Hour", { ns: "common" }),
        //                 //     })
        //                 // ),
        //             }),
        //     otherwise: () => Yup.array(),
        // }),
        // price: Yup.string().when("forWhom", {
        //     is: (forWhom) => forWhom && forWhom.value === "PARENT",
        //     then: () =>
        //         Yup.string().required(
        //             t("The {{label}} field is required.", {
        //                 ns: "validation",
        //                 label: t("Price per activity", { ns: "common" }),
        //             })
        //         ),
        //     otherwise: () => Yup.string(),
        // }),
        activityLength: Yup.number()
            .integer()
            .required(
                t("The {{label}} field is required.", {
                    ns: "validation",
                    label: t("Activity length", { ns: "common" }),
                })
            ),
        // groupSize: Yup.number().when("forWhom", {
        //     is: (forWhom) => forWhom && forWhom.value === "PARENT",
        //     then: () =>
        //         Yup.number()
        //             .integer()
        //             .required(
        //                 t("The {{label}} field is required.", {
        //                     ns: "validation",
        //                     label: t("Group size", { ns: "common" }),
        //                 })
        //             ),
        //     otherwise: () => Yup.number(),
        // }),
        territory: Yup.string().required(
            t("The {{label}} field is required.", {
                ns: "validation",
                label: t("Territorial scope", { ns: "common" }),
            })
        ),
        description: Yup.string().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Description", { ns: "common" }) })
        ),
    });

    return (
        <>
            <ContentHeading tag="h3">
                {activity ? t("Edit activity", { ns: "common" }) : t("Add activities", { ns: "common" })}
            </ContentHeading>

            <Box>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Formik
                        initialValues={getInitialValues(defaultValues, activity)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleSubmit(values, setSubmitting, resetForm);
                        }}
                        validateOnMount
                        enableReinitialize
                    >
                        {({
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            handleBlur,
                            setFieldValue,
                            setFieldTouched,
                            values,
                            validateForm,
                        }) => {
                            return (
                                <Form key={"activityForm"}>
                                    <div className="grid grid-cols-6 gap-6">
                                        <Field
                                            component={TextField}
                                            key={"name"}
                                            name={"name"}
                                            label={t("Name", { ns: "common" })}
                                            required={true}
                                            maxLength={255}
                                            fieldClassName={"col-span-full"}
                                        />
                                        <Field
                                            component={SelectField}
                                            key={"category"}
                                            name={"category"}
                                            label={t("Category", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full md:col-span-2"}
                                            handleBlur={handleBlur}
                                            options={activityCategoriesOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "common" })}
                                            onChange={(event, value) => {
                                                // setFieldTouched("category");
                                                setFieldValue("category", value);
                                                setFieldValue("type", "");
                                                value && value.id
                                                    ? loadTypeByCategory(value.id)
                                                    : setActivityTypesOptions([]);
                                            }}
                                        />
                                        <Field
                                            component={SelectField}
                                            key={"type"}
                                            name={"type"}
                                            label={t("Type", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full md:col-span-2"}
                                            handleBlur={handleBlur}
                                            options={activityTypesOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "common" })}
                                        />
                                        <Field
                                            component={SelectField}
                                            key={"city"}
                                            name={"city"}
                                            label={t("City", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full md:col-span-2"}
                                            handleBlur={handleBlur}
                                            options={activityCityOptions}
                                        />
                                        <Field
                                            component={SelectField}
                                            key={"period"}
                                            name={"period"}
                                            label={t("Period", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full md:col-span-2"}
                                            disabled={activity && true}
                                            handleBlur={handleBlur}
                                            options={activityPeriodOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                            onChange={(event, value) => {
                                                setFieldValue("period", value);
                                                values.forWhom &&
                                                    setFieldValue(
                                                        "billingMethods",
                                                        getBillingMethods(values.forWhom, value)
                                                    );
                                            }}
                                        />
                                        {values.period &&
                                            values.period.value === "SINGLE" &&
                                            values.date.map((d, i) => (
                                                <Fragment key={i}>
                                                    <Field
                                                        component={DatePicker}
                                                        key={"date.day." + i}
                                                        name={"date[" + i + "][day]"}
                                                        minDate={dayjs()}
                                                        label={t("Date of activity", { ns: "common" })}
                                                        fieldClassName={"col-span-full md:col-span-2"}
                                                    />
                                                    <Field
                                                        component={TimePicker}
                                                        key={"date.time." + i}
                                                        name={"date[" + i + "][time]"}
                                                        label={t("Hour", { ns: "common" })}
                                                        required={true}
                                                        fieldClassName={"col-span-full md:col-span-2"}
                                                    />
                                                </Fragment>
                                            ))}
                                        <Field
                                            component={SelectField}
                                            key={"forWhom"}
                                            name={"forWhom"}
                                            label={t("For whom", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"!col-start-1 col-span-full md:col-span-2"}
                                            handleBlur={handleBlur}
                                            options={activityForWhomOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                            onChange={(event, value) => {
                                                setFieldValue("forWhom", value);
                                                values.period &&
                                                    setFieldValue(
                                                        "billingMethods",
                                                        getBillingMethods(value, values.period)
                                                    );
                                            }}
                                        />
                                        <Field
                                            component={SelectField}
                                            key={"freeActivity"}
                                            name={"freeActivity"}
                                            label={t("Free activity", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-3 md:col-span-2"}
                                            handleBlur={handleBlur}
                                            getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                            options={[
                                                {
                                                    name: "No",
                                                    value: false,
                                                },
                                                {
                                                    name: "Yes",
                                                    value: true,
                                                },
                                            ]}
                                        />
                                        <Field
                                            component={NumberField}
                                            key={"activityLength"}
                                            name={"activityLength"}
                                            label={t("Activity length", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-3 md:col-span-2"}
                                        />
                                        {values.freeActivity &&
                                            values.freeActivity.value === false &&
                                            values.billingMethods.map((bM, index) => (
                                                <div
                                                    className="!col-start-1 col-span-full"
                                                    key={"billingMethod_" + index}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        "billingMethods[" + index + "][active]",
                                                                        e.target.checked
                                                                    );
                                                                }}
                                                                name={"billingMethods[" + index + "][active]"}
                                                                checked={bM.active}
                                                                color="default"
                                                            />
                                                        }
                                                        label={t("Pay {{whoPay}} - {{billingMethod}}", {
                                                            ns: "common",
                                                            whoPay: t(bM.whoPay.name, { ns: "lexicons" }),
                                                            billingMethod: t(bM.billingMethod.name, {
                                                                ns: "lexicons",
                                                            }),
                                                        })}
                                                        disabled={
                                                            bM.billingMethod.value ===
                                                                "INSTITUTION_PAY_PER_KID_ACTIVITY" ||
                                                            bM.billingMethod.value === "INSTITUTION_PAY_PER_KID"
                                                                ? true
                                                                : false
                                                        }
                                                    />

                                                    <p
                                                        className={
                                                            "text-sm italic m-0 " +
                                                            (bM.billingMethod.value ===
                                                                "INSTITUTION_PAY_PER_KID_ACTIVITY" && "opacity-40")
                                                        }
                                                    >
                                                        {renderBillingMethodDescription(bM.billingMethod.value)}
                                                    </p>

                                                    {bM.active === true && (
                                                        <div className="grid grid-cols-6 gap-6 mt-6">
                                                            <Field
                                                                component={PriceField}
                                                                key={"billingMethods[" + index + "][price]"}
                                                                name={"billingMethods[" + index + "][price]"}
                                                                label={getPriceLabel(bM.billingMethod.value)}
                                                                required={true}
                                                                fieldClassName={"col-span-full md:col-span-2"}
                                                            />
                                                            <Field
                                                                component={NumberField}
                                                                key={"billingMethods[" + index + "][minGroupSize]"}
                                                                name={"billingMethods[" + index + "][minGroupSize]"}
                                                                label={t("Minimum group size", {
                                                                    ns: "common",
                                                                })}
                                                                fieldClassName={"col-span-3 md:col-span-1"}
                                                                decimalScale={0}
                                                            />
                                                            <Field
                                                                component={NumberField}
                                                                key={"billingMethods[" + index + "][maxGroupSize]"}
                                                                name={"billingMethods[" + index + "][maxGroupSize]"}
                                                                label={t("Maximum group size", {
                                                                    ns: "common",
                                                                })}
                                                                fieldClassName={"col-span-3 md:col-span-1"}
                                                                decimalScale={0}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        <Field
                                            component={TextField}
                                            key={"territory"}
                                            name={"territory"}
                                            label={t("Territorial scope", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full"}
                                        />
                                        <Field
                                            component={TextArea}
                                            key={"description"}
                                            name={"description"}
                                            label={t("Description", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full"}
                                        />
                                        <Button
                                            type={"submit"}
                                            className={
                                                "btn-primary !col-start-1 col-span-3 md:col-span-2 xl:col-span-1"
                                            }
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                        </Button>
                                        <Link
                                            to={"/brand/brands"}
                                            className={"btn btn-gray col-span-3 md:col-span-2 xl:col-span-1"}
                                            disabled={isSubmitting}
                                        >
                                            {t("Cancel", { ns: "button" })}
                                        </Link>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Box>
        </>
    );
}

export default ActivityForm;
