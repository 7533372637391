import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatPrice, FormatTime } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Modal } from "components/ui";
import { DisplayDayTime } from "utils/DisplayDate";

function ActivityModalView({ data, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [enrollment, setEnrollment] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "enrollments/" + data.id,
            method: "GET",
        })
            .then((response) => {
                setEnrollment(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [data]);

    return (
        <Modal handleClose={closeModal} title={t("Information about activity", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : enrollment ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity", { ns: "common" })}:</strong>
                            {enrollment.activity.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Description", { ns: "common" })}:</strong>
                            {enrollment.activity.description}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Brand", { ns: "common" })}:</strong>
                            {enrollment.brand.name}
                        </div>
                        {enrollment.activity.period.value === "SINGLE" ? (
                            <>
                                {enrollment.date.map((d, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Date", { ns: "common" })}:</strong>
                                                <FormatDate date={d.day} />
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Hour", { ns: "common" })}:</strong>
                                                <FormatTime
                                                    date={d.time}
                                                    formatting={{ hour: "numeric", minute: "numeric" }}
                                                />
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="flex items-center gap-2">
                                <strong>{t("Dates", { ns: "common" })}:</strong>
                                {enrollment.date.map((d, index) => (
                                    <div key={index}>
                                        <DisplayDayTime day={d.day.name} time={d.time} />
                                        <br />
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="flex items-center gap-2">
                            <strong>{t("Where", { ns: "common" })}:</strong>
                            {t(enrollment.type.name, { ns: "lexicons" })}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Period", { ns: "common" })}:</strong>
                            {t(enrollment.activity.period.name, { ns: "lexicons" })}
                        </div>
                        {enrollment.whoPay && enrollment.whoPay.value === "PAY_PARENT" && (
                            <div className="flex items-center gap-2">
                                <strong>{t("Price per activity", { ns: "common" })}:</strong>
                                <FormatPrice price={enrollment.billing.price} />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
}

export default ActivityModalView;
