import { Checkbox, Drawer, FormControlLabel } from "@mui/material";
import { Box } from "components/ui";
import { useTranslation } from "react-i18next";

export const SidebarLeft = ({
    store,
    mdAbove,
    isReLoading,
    leftSidebarOpen,
    leftSidebarWidth,
    handleCalendarsUpdate,
    handleLeftSidebarToggle,
}) => {
    const { t } = useTranslation();

    const calendarsArr = store.calendars ? Object.entries(store.calendars) : [];

    const renderFilters = calendarsArr.length
        ? calendarsArr.map(([key, value]) => {
              return (
                  <FormControlLabel
                      key={key}
                      label={key === "General" ? t("General calendar", { ns: "common" }) : key}
                      sx={{ "& .MuiFormControlLabel-label": { color: "text.secondary" } }}
                      control={
                          <Checkbox
                              disabled={isReLoading}
                              checked={store.selectedCalendars.includes(key)}
                              onChange={() => handleCalendarsUpdate(key)}
                              sx={{
                                  "& .MuiSvgIcon-root": { color: value },
                              }}
                          />
                      }
                  />
              );
          })
        : null;

    return (
        <Drawer
            open={leftSidebarOpen}
            onClose={handleLeftSidebarToggle}
            variant={mdAbove ? "permanent" : "temporary"}
            ModalProps={{
                disablePortal: true,
                disableAutoFocus: true,
                disableScrollLock: true,
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                zIndex: 3,
                display: "block",
                position: mdAbove ? "static" : "absolute",
                "& .MuiDrawer-paper": {
                    borderRadius: 1,
                    boxShadow: "none",
                    width: leftSidebarWidth,
                    borderTopRightRadius: 0,
                    alignItems: "flex-start",
                    borderBottomRightRadius: 0,
                    zIndex: mdAbove ? 2 : "drawer",
                    position: mdAbove ? "static" : "absolute",
                    maxHeight: "1027px",
                },
                "& .MuiBackdrop-root": {
                    borderRadius: 1,
                    position: "absolute",
                },
            }}
        >
            <Box className="!shadow-none">
                <div className="flex flex-col">{renderFilters}</div>
            </Box>
        </Drawer>
    );
};
