import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoDownloadOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import ApexBarChart from "components/charts/ApexBarChart";

function StatisticsGmv() {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ categories: [], series: [] });

    useEffect(() => {
        AxiosInstance({
            url: "dashboard/statistics/gmv",
            method: "GET",
        })
            .then((response) => {
                let data = {
                    categories: [],
                    series: [
                        {
                            name: "GMV płatności za zajęcia",
                            data: [],
                        },
                    ],
                };

                response.data.forEach((item) => {
                    data["categories"].push(item.year + "-" + item.month);
                    data["series"][0]["data"].push(item.total);
                });

                setData(data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    let menuOptions = [
        {
            text: t("Edit", { ns: "button" }),
            icon: <IoDownloadOutline size={"1.25em"} />,
        },
        {
            text: t("Group", { ns: "button" }),
            icon: <IoDownloadOutline size={"1.25em"} />,
            menuItemProps: {
                // onClick: () => openModal(params.row, "group"),
            },
        },
    ];

    const handleDateRangeChange = (value) => {
        setLoading(true);

        let data = {
            categories: [],
            series: [
                {
                    name: "GMV płatności za zajęcia",
                    data: [],
                },
            ],
        };

        AxiosInstance({
            url: "dashboard/statistics/gmv",
            method: "POST",
            data: {
                start: value.start.toDate(),
                end: value.end.toDate(),
            },
        })
            .then((response) => {
                response.data.forEach((item) => {
                    data["categories"].push(item.year + "-" + item.month);
                    data["series"][0]["data"].push(item.total);
                });

                setData(data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setData(data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <>
            <ApexBarChart
                isLoading={isLoading}
                isEnableRange={false}
                title={"Struktura GMV"}
                menuOptions={menuOptions}
                handleDateRangeChange={handleDateRangeChange}
                {...data}
            />
        </>
    );
}

export default StatisticsGmv;
