import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, LoadingSpinner } from "components/common";
import { PayTelForm } from "components/payments/PayTelForm";
import { useSessionStorage } from "hooks/useSessionStorage";
import { useTranslation } from "react-i18next";
import { Box, Button } from "components/ui";

export const PaymentPage = () => {
    const params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    const [activeGateways, setActiveGateways] = useState([]);
    const [activeGateway, setActiveGateway] = useState(null);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "payments/gateways/active",
                method: "GET",
            }).then((response) => {
                setActiveGateways(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [params.orderId]);

    const handleGoBack = () => {
        setSubmitting(true);
        navigate("/");
    };

    const handleSubmit = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "payments/pay",
            method: "POST",
            data: {
                orderId: params.orderId,
                gateway: activeGateway,
            },
        })
            .then((response) => {
                if (activeGateway === "PAY_TEL" && response.data.formContext) {
                    setStatus(response.data.paymentStatus);
                    setFormData(response.data);
                } else if (activeGateway === "IMOJE" && response.data.payUrl) {
                    window.location.replace(response.data.payUrl);
                    return;
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return isLoading ? (
        <LoadingSpinner isFull={true} />
    ) : status === "PENDING" && formData ? (
        <div className={"flex flex-col items-center h-full w-full p-6 lg:p-12 gap-8"}>
            <PayTelForm data={formData} />

            <Button
                type={"submit"}
                className={"btn btn-dark-outline"}
                onClick={() => handleGoBack(params.orderId)}
                disabled={isSubmitting}
            >
                {t("Return to the dashboard", { ns: "button" })}
            </Button>
        </div>
    ) : activeGateways ? (
        <div className={"flex flex-col items-center justify-between h-screen w-full p-6 lg:p-12 gap-8"}>
            <div className={"flex flex-col items-center gap-8"}>
                {activeGateways.length > 0 ? (
                    <>
                        <h3>{t("Choose a payment gateway", { ns: "common" })}</h3>
                        <div className={"flex flex-row items-center p-6 gap-8"}>
                            {activeGateways.map((gateway) => (
                                <Box
                                    key={gateway.value}
                                    className={
                                        "cursor-pointer border " +
                                        (activeGateway && activeGateway === gateway.value ? "border-primary" : "")
                                    }
                                    onClick={() => !isSubmitting && setActiveGateway(gateway.value)}
                                >
                                    <img src={gateway.img} alt={gateway.name} />
                                </Box>
                            ))}
                        </div>
                        <Button
                            type={"submit"}
                            className={"btn btn-primary"}
                            onClick={() => handleSubmit()}
                            disabled={!activeGateway || isSubmitting}
                        >
                            {t("Go to payments", { ns: "button" })}
                        </Button>
                    </>
                ) : (
                    <>
                        <h3>{t("No active payment gateways.", { ns: "common" })}</h3>
                        <p className="text-center">
                            {t("There is currently no active payment gateway.", { ns: "common" })}
                            <br />
                            {t("Try later or contact your system administrator.", { ns: "common" })}
                        </p>
                    </>
                )}
            </div>
            <Button
                type={"submit"}
                className={"btn btn-dark-outline"}
                onClick={() => handleGoBack(params.orderId)}
                disabled={isSubmitting}
            >
                {t("Return to the dashboard", { ns: "button" })}
            </Button>
        </div>
    ) : (
        <div className={"flex flex-col items-center min-h-screen w-full p-12 gap-8"}>
            <AccessDenied />

            <Button
                type={"submit"}
                className={"btn btn-dark-outline"}
                onClick={() => handleGoBack(params.orderId)}
                disabled={isSubmitting}
            >
                {t("Return to the dashboard", { ns: "button" })}
            </Button>
        </div>
    );
};
