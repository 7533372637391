import { AccessDenied, LoadingSpinner } from "components/common";
import { Box, ContentHeading } from "components/ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AxiosInstance from "utils/AxiosInstance";
import { ProfileForm } from "./ProfileForm";
import { ProfileAvatar } from "./ProfileAvatar";
import { PasswordUpdateForm } from "./PasswordUpdateForm";

export const Profile = () => {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        setLoading(true);

        AxiosInstance({
            url: "users",
            method: "GET",
        })
            .then((response) => {
                setUser(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">{t("Profile Details", { ns: "common" })}</ContentHeading>

            <ProfileAvatar />

            {isLoading ? (
                <Box>
                    <LoadingSpinner />
                </Box>
            ) : user ? (
                <>
                    <Box>
                        <ProfileForm user={user} handleLoadData={handleLoadData} />
                    </Box>
                    <Box>
                        <PasswordUpdateForm user={user} />
                    </Box>
                </>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};
