import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { Box, ContentHeading } from "components/ui";
import { InstitutionTeacherSchema } from "schemas/InstitutionTeacherSchema";

function TeacherForm({ teacher }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formSchema = InstitutionTeacherSchema();

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "teachers";
        let method = "POST";

        if (teacher) {
            url += "/" + teacher.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!teacher && response.status === 201) {
                    resetForm({ values: "" });
                    navigate("/institution/teachers/" + response.data.data.id + "/edit", { replace: true });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">
                {teacher ? t("Edit teacher", { ns: "common" }) : t("Add teacher", { ns: "common" })}
            </ContentHeading>

            <Box>
                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    initialValues={teacher}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonLink={"/institution/teachers"}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            </Box>
        </>
    );
}

export default TeacherForm;
