import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { DisplayDateTime, DisplayDayTime } from "utils/DisplayDate";
import { FormatDate, FormatPrice, FormatTime } from "utils/Format";
import { Modal } from "components/ui";
import { LoadingSpinner } from "components/common";

function ActivityModalView({ data, closeModal }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [enrollment, setEnrollment] = useState(null);

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "enrollments/" + data.id,
                method: "GET",
            }).then((response) => {
                setEnrollment(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [currentUser, data]);

    return (
        <Modal handleClose={closeModal} title={t("Information about activity", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : enrollment ? (
                <div className="flex flex-col gap-4 w-full">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Activity", { ns: "common" })}:</strong>
                            {enrollment.activity.name}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Brand", { ns: "common" })}:</strong>
                            {enrollment.brand.name}
                        </div>
                        {enrollment.activity.period.value === "SINGLE" ? (
                            <>
                                {enrollment.date.map((d) => {
                                    return (
                                        <>
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Date", { ns: "common" })}:</strong>
                                                <FormatDate date={d.day} />
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Hour", { ns: "common" })}:</strong>
                                                <FormatTime
                                                    date={d.time}
                                                    formatting={{ hour: "numeric", minute: "numeric" }}
                                                />
                                            </div>
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="flex items-center gap-2">
                                <strong>{t("Dates", { ns: "common" })}:</strong>
                                {enrollment.date.map((d, index) => (
                                    <div key={index}>
                                        <DisplayDayTime day={d.day.name} time={d.time} />
                                        <br />
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="flex items-center gap-2">
                            <strong>{t("Type of activity", { ns: "common" })}:</strong>
                            {t(enrollment.activity.period.name, { ns: "lexicons" })}
                        </div>
                        {enrollment.whoPay && (
                            <div className="flex items-center gap-2">
                                <strong>{t("Who pay", { ns: "common" })}:</strong>
                                {t(enrollment.whoPay.name, { ns: "lexicons" })}
                            </div>
                        )}
                        <div className="flex items-center gap-2">
                            <strong>{t("Price per activity", { ns: "common" })}:</strong>
                            {enrollment.isFree ? (
                                t("Free", { ns: "common" })
                            ) : (
                                <FormatPrice price={enrollment.billing.price} />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
}

export default ActivityModalView;
