import React from "react";
import { useTranslation } from "react-i18next";
import { IoFingerPrintOutline, IoPeopleOutline } from "react-icons/io5";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { DataGrid } from "components/grid";
import { ContentHeading, OptionMenu } from "components/ui";

function UsersList({ users }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const columns = [
        {
            field: "firstName",
            headerName: t("First name", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${value || "---"}`,
        },
        {
            field: "lastName",
            headerName: t("Last name", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${value || "---"}`,
        },
        {
            field: "phone",
            headerName: t("Phone", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${value || "---"}`,
        },
        {
            field: "email",
            headerName: t("Email", { ns: "common" }),
            width: 280,
            valueGetter: (value, row) => `${value || "---"}`,
        },
        {
            field: "institution",
            headerName: t("Institution", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "roles",
            headerName: t("Role", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            valueGetter: (value, row) => {
                return `${(value && t("short." + value[0], { ns: "roles" })) || "---"}`;
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            disableExport: true,
            width: 120,
            renderCell: (params) => {
                let options = [];

                currentUser.roles.includes("ROLE_SUPER_ADMIN") &&
                    currentUser.id !== params.row.id &&
                    options.push({
                        text: t("Impersonating", { ns: "button" }),
                        icon: <IoFingerPrintOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => handleImpersonating(params.row.id),
                        },
                    });

                return <OptionMenu options={options} />;
            },
        },
    ];

    const handleImpersonating = async (id) => {
        AxiosInstance({
            url: "signin/by-user",
            method: "POST",
            data: {
                id: id,
            },
        })
            .then((response) => {
                if (response.data.token) {
                    sessionStorage.setItem("impersonatingUid", response.data.token);
                    sessionStorage.setItem("user", null);
                    window.location = "/";
                }
            })
            .catch((error) => {
                setTimeout(() => {
                    // setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">
                <IoPeopleOutline size={"1.5rem"} />
                {t("Users", { ns: "common" })}
            </ContentHeading>

            <DataGrid rows={users} columns={columns} enableSearch={true} fileName={"Użytkownicy"} />
        </>
    );
}

export default UsersList;
